import React, { useContext, useEffect, useReducer, useState } from "react"
import { useQuery, useLazyQuery } from "@apollo/react-hooks"
import CONTEXT_QUERY from "../graphql/queries/context-query"
import { TOKEN_STORAGE_KEY, SPACE_STORAGE_KEY } from "../graphql"
import SyncStorage from "../utils/SyncStorage"

const AppStateContext = React.createContext(initialState)

const initialState = {
  loading: true,
  user: null,
  profile: null,
  space: null,
  spaceId: null,
  error: null,
  spaceId: null,
  client: null
}

const appStateReducer = (state, action) => {
  switch (action.type) {
    case "set_from_query":
      return {
        ...state,
        error: null,
        loading: false,
        profile: action.data.me ? action.data.me : null,
        space: action.data.space
      }
    case "set_error":
      return { ...state, error: action.error }
    case "set_loading":
      return { ...state, loading: action.value }
    case "set_space":
      return { ...state, spaceId: action.spaceId }
    case "sign_out":
      return { profile: null, user: null, spaceId: null, space: null }
    default:
      return state
  }
}

const _useAppState = (client, spaceId, handleSwitchSpace) => {
  const [
    contextQuery,
    { loading, error, data, refetch, called }
  ] = useLazyQuery(CONTEXT_QUERY, {
    fetchPolicy: "cache-and-network"
  })

  const [state, dispatch] = useReducer(appStateReducer, {
    ...initialState,
    client,
    spaceId
  })

  useEffect(() => {
    if (state.spaceId) {
      contextQuery()
    } else {
      dispatch({ type: "set_loading", value: false })
    }
  }, [state.spaceId])

  const setSpace = spaceId => {
    SyncStorage.setItem(SPACE_STORAGE_KEY, spaceId)
    dispatch({ type: "set_space", spaceId })
    handleSwitchSpace(spaceId)
  }

  useEffect(() => {
    if (!loading && !error && data && state.spaceId) {
      dispatch({ type: "set_from_query", data: { ...data } })
    }
    if (error) {
      dispatch({ type: "set_error", error })
    }
  }, [loading, error, data, state.spaceId])

  const selectProfile = id => {
    return state.space.profiles.find(p => p.id === id)
  }

  const signOut = async () => {
    SyncStorage.removeItem(TOKEN_STORAGE_KEY)
    SyncStorage.removeItem(SPACE_STORAGE_KEY)
    await client.clearStore()
    await client.resetStore()
    dispatch({ type: "sign_out" })
  }

  return {
    state,
    actions: { selectProfile, signOut, setSpace }
  }
}

export const AppStateProvider = ({
  children,
  spaceId,
  client,
  handleSwitchSpace
}) => {
  const contextValue = _useAppState(client, spaceId, handleSwitchSpace)
  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  return useContext(AppStateContext)
}

export default useAppState
