import React, { useEffect, useRef, useState, useCallback } from "react"
import {
  Dimensions,
  KeyboardAvoidingView,
  FlatList,
  RefreshControl
} from "react-native"
import { useTheme } from "../../theme"
import Header from "../ui/Header"
import { Container, Loading, ErrorMessage } from "../ui"
import FeedbackInput from "./FeedbackInput"
import Message from "./Message"
import useMessages from "../../hooks/useMessages"
import useMe from "../../hooks/useMe"
import useThreadedMessagees from "../../hooks/useThreadedMessages"
import useDimensions from "../../hooks/useDimensions"

const Thread = ({ thread, isFocused, selectedPoints }) => {
  const profile = useMe()
  const listRef = useRef()
  const [theme] = useTheme()
  const [selectedMessage, setSelectedMessage] = useState()
  const [showScrollBar, setShowScrollBar] = useState(false)
  const { isMobile } = useDimensions()

  const {
    actions: { reload }
  } = useMessages()

  const [refreshing, setRefreshing] = useState(false)
  const handleRefresh = async () => {
    setRefreshing(true)
    await reload()
    setRefreshing(false)
  }

  const messages = useThreadedMessagees(thread)

  const doDelayedScroll = useCallback(() => {
    setTimeout(() => {
      if (listRef && listRef.current) {
        try {
          listRef.current.scrollToIndex({
            index: messages.length - 1,
            animated: false
          })
        } catch (error) {
          console.log("error scrolling to index: ", error)
        }
      }
    }, 100)
  }, [messages.length])

  useEffect(() => {
    if (messages.length && isFocused) {
      doDelayedScroll()
      setShowScrollBar(true)
    }
  }, [messages.length, isFocused])

  const handleSelectMessage = message => {
    setSelectedMessage(message)
  }

  const handleFinishMessage = () => {
    setSelectedMessage(null)
  }

  const subject = thread.from.id === profile.id ? thread.to : thread.from

  return (
    <Container
      style={{
        width: "auto",
        position: "relative",
        height: "100%",
        paddingBottom: theme.padding,
        justifyContent: "flex-end"
      }}
    >
      <Header profile={subject} back={isMobile} />
      <FlatList
        key={thread.id}
        ref={listRef}
        style={{ flex: 1 }}
        // onRefresh={handleRefresh}
        // refreshing={refreshing}
        refreshControl={
          <RefreshControl
            tintColor={theme.palette.textSecondary}
            colors={[theme.palette.textSecondary]}
            refreshing={refreshing}
            onRefresh={handleRefresh}
          />
        }
        showsVerticalScrollIndicator={showScrollBar}
        contentContainerStyle={{
          backgroundColor: theme.palette.background,
          paddingBottom: theme.padding * 3
        }}
        data={messages}
        onScrollToIndexFailed={e => {
          doDelayedScroll()
        }}
        keyExtractor={(message, i) => `message-${i}-${message.clientTime}`}
        renderItem={({ item: message, index }) => {
          const justifyContent =
            message.from.id === profile.id ? "flex-end" : "flex-start"
          const isLast = index + 1 === messages.length
          return (
            <Container
              style={{
                justifyContent,
                width: "100%",
                // paddingHorizontal: theme.padding,
                marginBottom: theme.margin
                // marginHorizontal: theme.margin,
              }}
            >
              <Message
                message={message}
                isLast={isLast}
                onSelect={handleSelectMessage}
              />
            </Container>
          )
        }}
      />
      {/* <KeyboardAvoidingView behavior="position" enabled> */}
      <FeedbackInput
        thread={thread}
        selectedMessage={selectedMessage}
        onFinishMessage={handleFinishMessage}
        selectedPoints={selectedPoints}
      />
      {/* </KeyboardAvoidingView> */}
    </Container>
  )
}

const ThreadContainer = ({ navigation }) => {
  const threadId = navigation.getParam("threadId")
  const selectedPoints = navigation.getParam("points") || []
  const [thread, setThread] = useState()
  const [isFocused, setIsFocused] = useState(false)
  const [read, setRead] = useState(false)

  const {
    state: { loading, error, threads },
    actions: { getOrSelectThread, markAsRead }
  } = useMessages()

  navigation.addListener("willFocus", () => {
    setIsFocused(true)
  })

  useEffect(() => {
    if (thread && !read) {
      markAsRead(thread.id)
      setRead(true)
    }
  }, [thread, read])

  useEffect(() => {
    if (threads[threadId]) {
      const nextThread = threads[threadId]
      setThread(nextThread)
      // markAsRead(threadId)
    } else {
      if (!loading) {
        const to = navigation.getParam("to")
        const from = navigation.getParam("from")
        if (to && from) {
          const thread = getOrSelectThread({ from, to })
          navigation.navigate("Thread", {
            threadId: thread.id,
            to: thread.to,
            from: thread.from
          })
          // getOrSelectThread({ to, from })
        } else {
          navigation.navigate("Inbox")
        }
      }
    }
  }, [threads, threadId, loading])

  useEffect(() => {
    if (!loading && !thread) {
      navigation.navigate("Inbox")
    }
  }, [loading, thread])

  if (loading || !thread)
    return (
      <Container full center>
        <Loading />
      </Container>
    )

  if (error) return <ErrorMessage error={error} />

  return (
    <Thread
      thread={thread}
      selectedPoints={selectedPoints}
      isFocused={isFocused}
    />
  )
}

export default ThreadContainer
