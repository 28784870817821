import React from "react"
import { AppearanceProvider } from "react-native-appearance"
import { createAppContainer } from "react-navigation"
import { createStackNavigator } from "react-navigation-stack"
import { ApolloProvider } from "@apollo/react-hooks"
import { ThemeContextProvider } from "./theme"
import Thread from "./components/thread/Thread"
import Inbox from "./screens/Inbox"
import PointDecks from "./screens/PointDecks"
import People from "./screens/People"
import PointDeck from "./screens/PointDeck"
import Profile from "./screens/Profile"
import Settings from "./screens/Settings"
import NewFeedback from "./screens/NewFeedback"
import PointModal from "./screens/PointModal"
import AuthWithLink from "./screens/AuthWithLink"
import RequestAuthLink from "./screens/RequestAuthLink"
import RequestAuthLinkSuccess from "./screens/RequestAuthLinkSuccess"
import SignOut from "./screens/SignOut"
import Authenticate from "./screens/Authenticate"

import { Container, Loading, ErrorMessage, IconButton } from "./components/ui"
import { getClient, SPACE_STORAGE_KEY } from "./graphql"
import PointSelector from "./components/point/SimplePointSelector"

import { LocalizationContextProvider } from "./localization"
import { Linking, AppLoading } from "expo"
import useAppState, { AppStateProvider } from "./hooks/useAppState"
import Splash from "./screens/Splash"
import { MessagesProvider } from "./hooks/useMessages"
import { PointsProvider } from "./hooks/usePoints"
import { PeopleProvider } from "./hooks/usePeople"
import SyncStorage from "./utils/SyncStorage"

import "./config"
import createSwitchTabsNavigator from "./navigator"

const persistenceKey = "nav:9.52"

const persistNavigationState = async navState => {
  try {
    SyncStorage.setItem(persistenceKey, JSON.stringify(navState))
  } catch (err) {
    // handle the error according to your neejds
  }
}
const loadNavigationState = async () => {
  const jsonString = SyncStorage.getItem(persistenceKey)
  return JSON.parse(jsonString)
}
function getPersistenceFunctions() {
  return __DEV__ && false
    ? {
        persistNavigationState,
        loadNavigationState
      }
    : undefined
}

const InboxStack = createStackNavigator(
  {
    InboxScreen: { screen: Inbox, path: "/" },
    Thread: { screen: Thread, path: "/thread/:threadID" }
  },
  {
    headerMode: "none",
    transparentCard: true
  }
)

const modalStackConfig = {
  headerMode: "none",
  mode: "modal",
  transparentCard: true,
  cardStyle: { opacity: 1 }
}

const AppStack = createStackNavigator(
  {
    Inbox: { screen: InboxStack, path: "inbox" },
    People: { screen: People, path: "people" },
    PointDecks: { screen: PointDecks, path: "points" },
    PointDeck: { screen: PointDeck, path: "points/:id" },
    Settings: { screen: Settings, path: "settings" },
    PointSelector: { screen: PointSelector, path: "select-point" },
    NewFeedback: { screen: NewFeedback, path: "new-feedback" },
    Profile: { screen: Profile, path: "profile/:id" },
    PointModal: { screen: PointModal, path: "point/:id" }
  },
  modalStackConfig
)

const AuthStack = createStackNavigator(
  {
    Authenticate: { screen: Authenticate, path: "auth" },
    AuthWithLink: { screen: AuthWithLink, path: "auth-link" },
    RequestAuthLink: { screen: RequestAuthLink, path: "request-link" },
    RequestAuthLinkSuccess: {
      screen: RequestAuthLinkSuccess,
      path: "request-link-success"
    },
    SignOut: { screen: SignOut, path: "sign-out" }
  },
  {
    headerMode: "none",
    transparentCard: true
  }
)

// const AuthAppSwitch = createSwitchNavigator(
//   {
//     Splash: { screen: Splash, path: "splash/:linkId" },
//     Auth: { screen: AuthStack, path: "auth" },
//     App: { screen: AppStack, path: "" }
//   },
//   {
//     initialRouteName: "Splash",
//     transparentCard: true
//   }
// )
const AuthAppSwitch = createSwitchTabsNavigator(
  {
    Splash: { screen: Splash, path: "splash/:linkId" },
    Auth: { screen: AuthStack, path: "auth" },
    App: { screen: AppStack, path: "" }
  },
  {
    initialRouteName: "Splash",
    transparentCard: true
  }
)

const AppContainer = createAppContainer(AuthAppSwitch)

const App = () => {
  const prefix = Linking.makeUrl("/")
  // const navigation = useNavigation()
  const { state } = useAppState()
  const { error, loading, profile } = state

  return (
    <Container full style={{ maxWidth: "100%" }}>
      {loading || error ? (
        <Container full center>
          {error ? (
            <ErrorMessage error={error} />
          ) : loading ? (
            <Loading />
          ) : null}
        </Container>
      ) : (
        <AppContainer {...getPersistenceFunctions()} uriPrefix={prefix} />
      )}
    </Container>
  )
}

class AppWrapper extends React.Component {
  state = { initialized: false, spaceId: null, client: null }

  async componentWillMount() {
    await SyncStorage.init()
    const spaceId = SyncStorage.getItem(SPACE_STORAGE_KEY)
    const client = getClient(spaceId)
    this.setState({ initialized: true, spaceId, client })
  }

  handleSwitchSpace = spaceId => {
    const client = getClient(spaceId)
    this.setState({ spaceId, client })
  }

  render() {
    if (!this.state.initialized) return <AppLoading />

    return (
      <ApolloProvider client={this.state.client}>
        <AppearanceProvider>
          <AppStateProvider
            {...this.state}
            handleSwitchSpace={this.handleSwitchSpace}
          >
            <PointsProvider>
              <PeopleProvider>
                <MessagesProvider>
                  <LocalizationContextProvider>
                    <ThemeContextProvider>
                      <App />
                    </ThemeContextProvider>
                  </LocalizationContextProvider>
                </MessagesProvider>
              </PeopleProvider>
            </PointsProvider>
          </AppStateProvider>
        </AppearanceProvider>
      </ApolloProvider>
    )
  }
}

// let HotApp = AppWrapper
// if (Platform.OS === "web") AppWrapper = hot(module)(AppWrapper)
export default AppWrapper
