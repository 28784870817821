import React from "react"
import { useTheme } from "../../theme"
import { AnimatedCircularProgress } from "react-native-circular-progress"

const CircleProgress = ({
  value,
  size,
  width,
  backgroundColor,
  color,
  style,
  animate,
  children
}) => {
  const [theme] = useTheme()
  return (
    <AnimatedCircularProgress
      backgroundColor={backgroundColor || theme.palette.background}
      tintColor={color || theme.palette.accent}
      fill={value * 100}
      size={size}
      width={width}
      style={style}
      rotation={0}
      duration={animate ? 400 : 0}
    >
      {() => children}
    </AnimatedCircularProgress>
  )
}

CircleProgress.defaultProps = {
  size: 20,
  width: 2,
  animate: true
}

export default CircleProgress
