import gql from "graphql-tag"
import profileFragment from "../fragments/profile-fragment"
import spaceFragment from "../fragments/space-fragment"

export default gql`
  query {
    spaceProfiles {
      ...ProfileFragment
      space {
        ...SpaceFragment
      }
    }
  }
  ${profileFragment}
  ${spaceFragment}
`
