import React from "react"
import pluralize from "pluralize"
import Container from "../components/ui/Container"
import { Title, Text, TextSecondary, Label } from "../components/ui/Text"
import { useTheme } from "../theme"
import { Dimensions, FlatList } from "react-native"
import Modal from "../components/ui/Modal"
import usePoints from "../hooks/usePoints"
import CircleProgress from "../components/ui/CircleProgress"
import Avatar from "../components/ui/Avatar"
import PROFILE_QUERY from "../graphql/queries/profile-query"
import { useQuery } from "@apollo/react-hooks"

import { Loading, ErrorMessage, Icon } from "../components/ui"
import { getScaleFromInput } from "../components/thread/scale"
import PointFeedback from "../components/point/PointFeedback"
import { useLocalization } from "../localization"
import PointList from "../components/point/PointList"

const CardModal = ({ children }) => {
  const [theme] = useTheme()
  return (
    <Modal
      useCloseButton
      contentContainerStyle={{
        position: "relative",
        padding: 0,
        paddingTop: 0,
        minHeight: 220,
        // maxHeight: 400,
        height: "auto",
        flex: "auto",
        display: "flex",
        width: "90%",
        maxWidth: 600,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.surface
      }}
    >
      {children}
    </Modal>
  )
}

const PointModal = ({
  pointDefinition,
  showActivity,
  point,
  points,
  stats,
  profile
}) => {
  const [theme] = useTheme()
  const { formatDate } = useLocalization()

  const handleSelectInfo = () => {
    // todo: open the point info card
  }

  const renderScale = () => {
    const { scale } = pointDefinition
    const qualitativeValue = getScaleFromInput(scale, point.quantitativeValue)
      .label
    return (
      <Container column transparent>
        {scale.map((s, i) => (
          <Container
            key={i}
            row
            pad
            round
            style={{
              width: "auto",
              marginBottom: theme.margin
            }}
          >
            <CircleProgress
              value={s.value}
              style={{ marginRight: theme.margin }}
            />
            <Text>{s.label}</Text>
            {profile && qualitativeValue === s.label ? (
              <Avatar
                size={24}
                profile={profile}
                style={{ marginLeft: theme.margin }}
              />
            ) : null}
          </Container>
        ))}
      </Container>
    )
  }

  const renderValue = () => {
    if (pointDefinition.scale) return renderScale()
    if (point.qualitativeValue) return <Label>{point.qualitativeValue}</Label>
    return null
  }

  const renderStatsStatement = () => {
    return `${pluralize("point", stats.count, true)} from ${pluralize(
      "person",
      stats.fromCount,
      true
    )}`
  }
  const renderDateStatement = () => {
    if (stats.count <= 1) return null
    return (
      <TextSecondary style={{ textAlign: "center" }}>{`${formatDate(
        stats.firstUpdated
      )} - ${formatDate(stats.lastUpdated)}`}</TextSecondary>
    )
  }

  return (
    <>
      {/* <IconButton
        icon="book"
        onPress={handleSelectInfo}
        style={{
          position: "absolute",
          right: theme.padding,
          top: theme.padding
        }}
      /> */}
      <Container transparent pad center>
        <CircleProgress
          value={point.quantitativeValue || 1}
          style={{ marginVertical: theme.margin }}
        />
        <Title style={{ marginBottom: theme.margin }}>
          {pointDefinition.label}
        </Title>
        {pointDefinition.description ? (
          <Text style={{ textAlign: "center", marginBottom: theme.margin }}>
            {pointDefinition.description}
          </Text>
        ) : null}
        {/* <Title sub style={{ marginBottom: theme.margin }}>
        Scale
      </Title> */}
        {renderValue()}
      </Container>

      {showActivity ? (
        <Container transparent pad>
          <Container transparent row center>
            <Icon icon="activity" color={theme.palette.accent} />
            <Title sub>Activity</Title>
          </Container>
          <Text style={{ textAlign: "center" }}>{renderStatsStatement()}</Text>
          {renderDateStatement()}
          <PointList points={points} style={{ maxHeight: 200 }} />
        </Container>
      ) : null}
    </>
  )
}

// import POINTS_QUERY from "../graphql/queries/points-query"

const PointModalContainer = ({ navigation }) => {
  const { selectPoint } = usePoints()

  // const {data, loading, error} = useQuery(POINTS_QUERY, {fetchPolicy: "cache-and-network"})

  const pointId = navigation.getParam("pointId")
  const pointValue = navigation.getParam("pointValue")
  const showActivity = navigation.getParam("showActivity")
  const pointDefinition = selectPoint(pointId)

  const { data, loading, error } = useQuery(PROFILE_QUERY, {
    variables: { id: pointValue.toId, withPoints: true },
    fetchPolicy: "cache-and-network"
  })

  const renderView = () => {
    if (loading) return <Loading />
    if (error) return <ErrorMessage error={error} />
    const points = (data.profile.points || []).filter(pt => pt.key === pointId)
    const stats = data.profile.snapshot[pointId]
    return (
      <PointModal
        pointDefinition={pointDefinition}
        point={pointValue}
        profile={data.profile}
        showActivity={showActivity}
        points={points}
        stats={stats}
      />
    )
  }

  return <CardModal>{renderView()}</CardModal>
}

export default PointModalContainer
