import gql from "graphql-tag"
import pointFragment from "../fragments/point-fragment"
import profileFragment from "../fragments/profile-fragment"

export default gql`
  query ProfileActivity($id: ID!) {
    profile(id: $id) {
      id
      allPoints {
        ...PointFragment
        from {
          ...ProfileFragment
        }
        to {
          ...ProfileFragment
        }
      }
    }
  }
  ${profileFragment}
  ${pointFragment}
`
