import { Platform } from "react-native"
import * as Haptics from "expo-haptics"

const hapticImpact = style => {
  if (Platform.OS !== "web") {
    return Haptics.impactAsync(style)
  } else {
    return Promise.resolve()
  }
}
export default hapticImpact
