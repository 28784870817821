import React, { useContext, useEffect } from "react"
import * as Localization from "expo-localization"
import i18n from "i18n-js"
import useStorage from "./hooks/useStorage"
import moment from "moment"
import "moment/min/locales"

export const languagues = [
  {
    label: "English",
    locale: "en",
    translations: {
      actions: {
        giveFeedback: "Give Feedback"
      },
      user: {
        you: "You"
      },
      thread: {
        otherFeedbackSubjectPrefix: "Feedback with",
        selfFeedbackSubjectPrefix: "Self feedback",
        fromSelfPrefix: "You: "
      },
      feedback: {
        title: "New feedback",
        searchPrompt: "Search..."
      },
      points: {
        label: "point",
        title: "Select feedback",
        search: "Search...",
        give: "Give to",
        request: "Request from"
      },
      inbox: {
        title: "Inbox"
      },
      settings: {
        title: "Settings",
        theme: "Theme",
        language: "Language"
      }
    }
  },
  {
    label: "Spanish",
    locale: "es",
    translations: {
      actions: {
        giveFeedback: "Provee Retroalimentación"
      },
      points: {
        label: "punto"
      },
      inbox: {
        title: "Mensajes"
      },
      thread: {
        otherFeedbackSubjectPrefix: "Retroalimentación con",
        selfFeedbackSubjectPrefix: "Autocomprobación",
        fromSelfPrefix: "Tú: "
      },
      settings: {
        title: "Ajustes",
        theme: "Teme",
        language: "Idioma"
      }
    }
  }
]

i18n.fallbacks = true
i18n.translations = languagues.reduce((ts, lng) => {
  ts[lng.locale] = lng.translations
  return ts
}, {})

// Hack to get around moment being dum
// see https://github.com/moment/moment/issues/3624
const toMomentLocale = locale => {
  let momentLocale = locale
  momentLocale = momentLocale.replace("_", "-").toLowerCase()
  momentLocale = ["en-gb", "en-us"].includes(locale)
    ? momentLocale
    : momentLocale.split("-")[0]
  return momentLocale
}

const LocalizationContext = React.createContext()

export const LocalizationContextProvider = ({ children }) => {
  const [locale, setLocale] = useStorage("locale", Localization.locale)
  i18n.locale = locale

  useEffect(() => {
    moment.locale(toMomentLocale(locale))
  }, [locale])

  const formatDate = (date, format = null) => {
    if (format) return moment(new Date(date)).format(format)
    return moment(new Date(date)).calendar(null, {
      sameDay: "h:mm A",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "MM/D"
    })
  }

  return (
    <LocalizationContext.Provider
      value={{ i18n, t: i18n.t, locale, setLocale, formatDate }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

export const useLocalization = () => {
  return useContext(LocalizationContext)
}

export default i18n
