import { AsyncStorage } from "react-native"

const createSyncStorage = () => {
  let _data = {}
  let _initialized = false
  let _initializing = false

  const init = async () => {
    // await AsyncStorage.clear()
    const keys = await AsyncStorage.getAllKeys()
    const values = await AsyncStorage.multiGet(keys)
    _data = values.reduce((data, [k, v]) => {
      data[k] = v
      return data
    }, {})
    _initialized = true
    // console.log("SyncStorage initialized...", Object.keys(_data))
  }

  const getItem = key => {
    return _data[key]
  }

  const setItem = (key, value) => {
    _data[key] = value
    AsyncStorage.setItem(key, value)
  }

  const removeItem = (key, value) => {
    delete _data[key]
    AsyncStorage.removeItem(key, value)
  }

  return { init, getItem, setItem, removeItem }
}

const SyncStorage = new createSyncStorage()

export default SyncStorage
