import React from "react"
import { Animated, Dimensions, Easing } from "react-native"
import { useTheme } from "../../../theme"
import useAnimation from "../../../hooks/useAnimation"
import hapticImpact from "../../../utils/hapticImpact"
import useMe from "../../../hooks/useMe"

import Feedback from "./Feedback"
import FeedbackRequest from "./FeedbackRequest"
import Section from "./Section"
import SentLabel from "./SentLabel"

const messageComponentMap = {
  feedback: Feedback,
  feedback_request: FeedbackRequest,
  section: Section
}

const Message = ({ message, onSelect, isLast, ...props }) => {
  const [theme] = useTheme()
  const profile = useMe()
  const Component = messageComponentMap[message.type]
  if (!Component) return null
  // Animate if this came in the last second
  const animate =
    message._local ||
    new Date().getTime() - new Date(message.created).getTime() < 1000

  const { lerp } = useAnimation({
    duration: animate ? 300 : 0,
    onFinish: async () => {
      if (animate) await hapticImpact("light")
    }
  })

  const isFromMe = message.from.id === profile.id
  const align = isFromMe ? "right" : "left"
  const justifyContent = {
    left: "flex-start",
    center: "center",
    right: "flex-end"
  }[align]

  return (
    <Animated.View
      style={{
        display: "flex",
        minWidth: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        paddingHorizontal: theme.padding,
        justifyContent,
        alignItems: justifyContent,
        opacity: lerp([0, 1]),
        transform: [
          {
            translateY: lerp(
              [Dimensions.get("window").height - 200, 0],
              Easing.in(Easing.elastic(0.6))
            )
          }
        ]
      }}
    >
      <Component
        message={message}
        justifyContent={justifyContent}
        onSelect={onSelect}
        {...props}
      />
      {isFromMe && isLast && message.sent ? (
        <SentLabel message={message} />
      ) : null}
    </Animated.View>
  )
}

export default Message
