import gql from "graphql-tag"
import profileFragment from "../fragments/profile-fragment"

export default gql`
  query Profiles {
    profiles {
      ...ProfileFragment
      snapshot
    }
  }
  ${profileFragment}
`
