import React from "react"
import { Icon as RNIcon } from "react-native-elements"
import { useTheme } from "../../theme"

const Icon = ({ icon, color, disabled, size, style, ...props }) => {
  const [theme] = useTheme()
  return (
    <RNIcon
      type="feather"
      name={icon}
      color={color || theme.palette.text}
      size={size}
      containerStyle={{ opacity: disabled === true ? 0.5 : 1, ...style }}
      {...props}
    />
  )
}

Icon.defaultProps = {
  size: 18
}

export default Icon
