import React from "react"
import PointScale from "./PointScale"
import Container from "../ui/Container"
import { Title, Paragraph } from "../ui"
import { useTheme } from "../../theme"

const PointInfoCard = ({ point }) => {
  const [theme] = useTheme()
  return (
    <Container
      surface
      shadow
      round
      column
      pad
      style={{
        width: "auto",
        minWidth: 300,
        maxWidth: 900,
        alignItems: "center",
        minHeight: 500,
        justifyContent: "flex-start"
      }}
    >
      <Container transparent column center pad>
        <Title>{point.label}</Title>
        {point.description ? (
          <Paragraph style={{ textAlign: "center" }}>
            {point.description}
          </Paragraph>
        ) : null}
      </Container>

      {point.scale ? <PointScale point={point} /> : null}

      {point.details ? (
        <Container
          transparent
          pad
          style={{
            borderTopWidth: theme.borderWidth,
            borderColor: theme.borderColor
          }}
        >
          <Paragraph
            style={{ fontSize: theme.fontSize.text + 3, fontWeight: "bold" }}
          >
            “{point.details}”
          </Paragraph>
        </Container>
      ) : null}
    </Container>
  )
}

export default PointInfoCard
