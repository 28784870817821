import React, { forwardRef } from "react"
import Color from "color"
import UITextInput from "../../ui/TextInput"
import { useTheme } from "../../../theme"

const TextInput = forwardRef(({ onChange, point, ...props }, ref) => {
  const [theme] = useTheme()
  const handleChange = text => {
    onChange({
      key: point.id,
      qualitativeValue: text
    })
  }
  return (
    <UITextInput
      {...props}
      onChangeText={handleChange}
      ref={ref}
      placeholderTextColor={new Color(theme.palette.text).alpha(0.5).toString()}
      style={{
        backgroundColor: theme.palette.background,
        borderColor: theme.palette.accent,
        width: "100%",
        ...props.style
      }}
    />
  )
})

export default TextInput
