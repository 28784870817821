import React from "react"
import Color from "color"
import { View } from "react-native"
import { useTheme } from "../../../theme"
import { TextInput, Text, Container, Icon } from "../../ui"

const Note = ({ text, mode, point, style, onChange, ...props }) => {
  const [theme] = useTheme()
  return (
    <Container surface pad row style={{ maxWidth: "100%" }} {...props}>
      <Icon icon="file-text" />
      {mode === "view" ? (
        <Text style={{ paddingHorizontal: theme.padding }}>{text}</Text>
      ) : (
        <TextInput
          style={{
            width: "100%",
            margin: 0,
            borderRadius: 0,
            borderWidth: 0,
            padding: 0,
            backgroundColor: "transparent"
          }}
          placeholder="Enter notes..."
          multiline
          onChangeText={text => onChange({ value: text, key: point.id })}
        />
      )}
    </Container>
  )
}

Note.defaultProps = {
  mode: "view"
}

export default Note
