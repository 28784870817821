import React from "react"
import { View } from "react-native"
import { useTheme } from "../../theme"
import { Paragraph } from "./Text"

const Bubble = ({ text, children, highlight, style, ...props }) => {
  const [theme] = useTheme()
  const backgroundColor = highlight
    ? theme.palette.accent
    : theme.palette.surface
  const edgeStyle = highlight
    ? { borderBottomRightRadius: 1 }
    : { borderBottomLeftRadius: 1 }
  const textColor = highlight ? theme.palette.inverseText : theme.palette.text
  return (
    <View
      style={{
        backgroundColor,
        borderRadius: theme.borderRadius,
        paddingHorizontal: theme.padding,
        marginTop: theme.margin,
        maxWidth: 280,
        ...edgeStyle,
        ...style
      }}
      {...props}
    >
      {text ? (
        <Paragraph
          style={{
            fontSize: 14,
            color: textColor
          }}
        >
          {text}
        </Paragraph>
      ) : null}
      {children}
    </View>
  )
}

Bubble.defaultProps = {
  highlight: false
}

export default Bubble
