import React from "react"
import { Container, Title, Text, Icon, Paragraph } from "../components/ui"
import Button from "../components/ui/Button"
import { useTheme } from "../theme"

const RequestAuthLinkSuccess = ({ navigation }) => {
  const email = navigation.getParam("email")
  const [theme] = useTheme()
  return (
    <Container full safe center>
      <Container full pad center style={{ maxWidth: "90%" }}>
        <Container row center>
          <Icon icon="mail" style={{ marginRight: theme.margin }} />
          <Title>Check your email!</Title>
        </Container>
        <Paragraph>
          Use the code or link in the email sent to {email} to sign in.
        </Paragraph>
        <Button
          link
          title="Back to sign in"
          onPress={() => navigation.navigate("Authenticate")}
        />
      </Container>
    </Container>
  )
}

export default RequestAuthLinkSuccess
