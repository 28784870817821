import React from "react"
import Container from "../ui/Container"
import { useTheme } from "../../theme"
import { Button } from "../ui"

const ProfileMenu = ({ onSelect, selectedOption, options }) => {
  const [theme] = useTheme()
  const handleSelect = op => () => {
    onSelect(op)
  }
  return (
    <Container row>
      {options.map((option, i) => {
        const selected = option.title === selectedOption.title
        return (
          <Button
            key={i}
            link
            icon={option.icon}
            title={option.title}
            highlighted={selected}
            labelStyle={{
              fontSize: theme.fontSize.heading2,
              fontWeight: "bold"
            }}
            onPress={handleSelect(option)}
          />
        )
      })}
    </Container>
  )
}

export default ProfileMenu
