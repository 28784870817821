import React from "react"
import TextInput from "./TextInput"
import Container from "./Container"
import { useTheme } from "../../theme"
import Icon from "./Icon"

const SearchInput = ({ prompt, onChange, style, ...props }) => {
  const [theme] = useTheme()
  return (
    <Container
      row
      round
      surface
      style={{
        borderWidth: theme.borderWidth,
        paddingLeft: theme.padding,
        ...style
      }}
    >
      <Icon icon="search" color={theme.palette.textSecondary} />
      <TextInput
        placeholder={prompt}
        onChangeText={t => onChange(t)}
        style={{
          backgroundColor: "transparent",
          borderWidth: 0
        }}
      />
    </Container>
  )
}

export default SearchInput
