import React from "react"
import { View, TouchableOpacity } from "react-native"
import { Text, Title, Container, Icon } from "../ui"
import { AnimatedCircularProgress } from "react-native-circular-progress"
import Trend from "../ui/TrendArrow"
import { useTheme } from "../../theme"
import { getScaleFromInput } from "../thread/scale"
import Bubble from "../ui/Bubble"
import Note from "../input/Note"
import { useNavigation } from "react-navigation-hooks"
import CircleProgress from "../ui/CircleProgress"
import { mapStylesToSize } from "../ui/utils"

const MultiPointInputFeedback = ({
  value,
  point,
  style,
  size,
  count,
  showQualitativeDescriptor
}) => {
  const [theme] = useTheme()

  const qv = (value && value.quantitativeValue) || null
  const valueLabel =
    qv !== null && point.scale
      ? getScaleFromInput(point.scale, qv).label
      : qv | ""
  let styles = {
    width: "auto",
    paddingVertical: theme.padding / 2,
    paddingHorizontal: theme.padding,
    ...style
  }
  let fontSize = theme.fontSize.text

  if (size === "small") {
    fontSize = theme.fontSize.textSecondary
    styles = {
      ...styles,
      paddingVertical: theme.padding / 2,
      paddingHorizontal: theme.padding
    }
  }

  return (
    <>
      {value !== null ? (
        <CircleProgress value={qv ? qv : 0} size={14} width={2} />
      ) : null}
      {showQualitativeDescriptor ? (
        <Text
          style={{
            color: theme.palette.textSecondary,
            fontSize,
            marginLeft: theme.margin / 2
          }}
        >
          {valueLabel}
        </Text>
      ) : null}
      <Title
        style={{
          fontSize,
          marginLeft: theme.margin / 2
        }}
      >
        {point.adjective || point.label}
      </Title>
      {/* {count ? (
        <View
          style={{
            backgroundColor: theme.palette.background,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 3,
            marginLeft: theme.margin,
            width: 22,
            height: 22,
            borderRadius: 11
          }}
        >
          <Text
            style={{
              fontSize: theme.fontSize.textSecondary
            }}
          >
            {count}
          </Text>
        </View>
      ) : null} */}
      {/* <Trend
        value={value}
        size={14}
        threshold={0.5}
        style={{ marginLeft: theme.margin / 2 }}
      /> */}
    </>
  )
}

const TextFeedback = ({ point, value }) => {
  const [theme] = useTheme()
  return (
    <Container row transparent style={{ width: "auto" }}>
      <Text>{point.label}</Text>
      <Icon icon="arrow-right" />
      <Text style={{ fontWeight: "bold" }}>{value.qualitativeValue}</Text>
    </Container>
  )
  // return <Bubble text={`${point.label} = ${value.qualitativeValue}`} />
}

const NoteFeedback = ({ point, value }) => {
  return <Note text={value} />
}

const PointFeedbackTypes = {
  MultiPointInput: MultiPointInputFeedback,
  Text: TextFeedback,
  Note: NoteFeedback
}

const PointFeedback = props => {
  const [theme] = useTheme()
  const navigation = useNavigation()
  const { showActivity, nonInteractive, point, value, styles } = props
  const handleSelect = () => {
    navigation.navigate("PointModal", {
      pointId: point.id,
      pointValue: value,
      showActivity
    })
  }

  const Component = PointFeedbackTypes[props.point.type || "MultiPointInput"]
  if (!Component) return null
  return Component ? (
    <TouchableOpacity onPress={handleSelect} disabled={nonInteractive === true}>
      <Container
        row
        center
        surface
        round
        style={{
          width: "auto",
          padding: theme.padding,
          paddingVertical: theme.padding / 2
        }}
      >
        <Component {...props} />
      </Container>
    </TouchableOpacity>
  ) : null
}

export default PointFeedback
