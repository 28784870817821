import React from "react"
import { Avatar as RNAvatar } from "react-native-elements"

const getProfileName = profile => {
  let title = ""
  if (profile.name) title = `${profile.name[0]}`
  if (profile.surname) title = `${title} ${profile.surname[0]}`
  return title
}

const Avatar = ({ profile, size, style, ...props }) => {
  if (profile.avatarUri) props.source = { uri: profile.avatarUri }

  return (
    <RNAvatar
      rounded
      size={size}
      title={getProfileName(profile)}
      containerStyle={style}
      {...props}
    />
  )
}

Avatar.defaultProps = {
  size: 50
}

export default Avatar
