import React, { useEffect } from "react"
import AnimatedActionButton from "../../ui/AnimatedActionButton"

import PointAssessmentDeck from "../../point/PointAssessmentDeck"
import RequestFeedbackInput from "./RequestFeedbackInput"
import useMe from "../../../hooks/useMe"
import useMessages from "../../../hooks/useMessages"
import { useNavigation } from "react-navigation-hooks"
import { Container } from "../../ui"
import { KeyboardAvoidingView } from "react-native"

import { useTheme } from "../../../theme"
import useFeedbackState from "../../../hooks/useFeedbackState"

const FeedbackInput = ({
  thread,
  selectedMessage,
  onFinishMessage,
  selectedPoints: initialSelectedPoints
}) => {
  const navigation = useNavigation()
  const [theme] = useTheme()
  // const [appState, _, appActions] = useAppState()
  const profile = useMe()
  const {
    actions: { sendMessage }
  } = useMessages()

  const { selectedPoints, from, to, contextID, actions } = useFeedbackState(
    thread
  )

  useEffect(() => {
    if (initialSelectedPoints.length)
      actions.selectPoints(initialSelectedPoints)
  }, [initialSelectedPoints])

  // The other user in the thread (e.g. not me)
  const peer = thread.from.id === profile.id ? thread.to : thread.from

  // This handles a feedback request input
  // TODO: consider moving all this stuff up to thread state
  useEffect(() => {
    if (selectedMessage) {
      if (selectedMessage.type === "feedback_request") {
        // Select points that don't have feedback yet
        const points = selectedMessage.data.points.reduce((pts, key) => {
          if (
            selectedMessage.pointProgress &&
            !selectedMessage.pointProgress[key]
          )
            pts.push({ id: key })
          return pts
        }, [])

        if (points.length > 0) {
          const payload = {
            feedbackFor: { id: selectedMessage.from },
            points,
            contextID: selectedMessage.id
          }
          handleSelectPoints(payload)
        } else {
          onFinishMessage(selectedMessage)
        }
      }
    }
  }, [selectedMessage])

  const handleChangePoints = () => {}

  const handleSelectPoints = ({ feedbackFor, points, contextID }) => {
    actions.selectPoints(points.map(p => p.id))
    actions.setTo(feedbackFor.id)
    actions.setFrom(profile.id)
    if (contextID) actions.setContext(contextID)
  }

  const handleSelectPoint = () => {
    navigation.navigate("PointSelector", {
      to: peer.id,
      onSelect: handleSelectPoints
    })
  }

  const handleComplete = data => {
    actions.selectPoints([])
  }

  const handlePointSubmit = ({
    key,
    message,
    qualitativeValue,
    quantitativeValue,
    data
  }) => {
    const points = [{ key, quantitativeValue, qualitativeValue, data }]
    sendMessage("feedback", peer, message, points)
  }
  const handleRequestFeedback = data => {
    actions.selectPoints([])
    //appActions.sendFeedbackRequest(thread.from, data.comment || "", data)
  }

  const givingFeedback =
    (to && to.id !== profile.id) || (to && to.id === profile.id)

  return selectedPoints.length ? (
    givingFeedback ? (
      <KeyboardAvoidingView behavior="position">
        <Container
          transparent
          pointerEvents="box-none"
          style={{
            position: "absolute",
            bottom: theme.padding,
            height: 400
          }}
        >
          <PointAssessmentDeck
            to={peer}
            points={selectedPoints}
            onSubmit={handlePointSubmit}
            onComplete={handleComplete}
          />
        </Container>
      </KeyboardAvoidingView>
    ) : (
      <RequestFeedbackInput
        points={selectedPoints}
        from={from}
        onSubmit={handleRequestFeedback}
        onChangePoints={handleChangePoints}
      />
    )
  ) : (
    <AnimatedActionButton
      icon="plus"
      onPress={() => handleSelectPoint(peer.id)}
      containerStyle={{ position: "absolute", bottom: theme.margin * 2 }}
    />
  )
}

export default FeedbackInput
