import React from "react"
import { View } from "react-native"
import { useTheme } from "../../theme"
import { Text } from "./Text"

const Badge = ({ count, highlight, style, size }) => {
  const [theme] = useTheme()
  return (
    <View
      style={{
        backgroundColor: highlight
          ? theme.palette.accent
          : theme.palette.background,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        width: size,
        height: size,
        borderRadius: size / 2,
        ...style
      }}
    >
      <Text
        style={{
          fontSize: theme.fontSize.textSecondary
        }}
      >
        {count}
      </Text>
    </View>
  )
}

Badge.defaultProps = {
  size: 22
}

export default Badge
