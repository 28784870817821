import React from "react"
import { Loading, ErrorMessage } from "../components/ui"
import Modal from "../components/ui/Modal"
import PROFILE_QUERY from "../graphql/queries/profile-query"
import { useQuery } from "@apollo/react-hooks"
import Profile from "../components/profile/Profile"

const ProfileScreen = ({ navigation, ...props }) => {
  const profileId = navigation.getParam("profileId")

  const { data, loading, error } = useQuery(PROFILE_QUERY, {
    variables: { id: profileId, withPoints: false },
    fetchPolicy: "cache-and-network"
  })

  const renderView = () => {
    if (loading) return <Loading />
    if (error) return <ErrorMessage error={error} />
    return <Profile navigation={navigation} profile={data.profile} {...props} />
  }

  return (
    <Modal contentContainerStyle={{ padding: 0 }} loading={loading}>
      {renderView()}
    </Modal>
  )
}

export default ProfileScreen
