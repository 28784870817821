import React from "react"
import { Container, IconButton } from "./components/ui"
import { getStatusBarHeight } from "react-native-iphone-x-helper"
import { createNavigator, TabRouter } from "react-navigation"
import useMe from "./hooks/useMe"
import useDimensions from "./hooks/useDimensions"
import { useTheme } from "./theme"
import { InboxView } from "./screens/Inbox"

const TabsNavigator = ({ navigation, descriptors }) => {
  const [theme] = useTheme()
  const profile = useMe()
  const { routes, index } = navigation.state
  const {
    window: { width }
  } = useDimensions()
  const isMobile = width < 600
  const descriptor = descriptors[routes[index].key]
  const ActiveScreen = descriptor.getComponent()

  const activeRouteName =
    descriptor && descriptor.state.routes
      ? descriptor.state.routes[descriptor.state.routes.length - 1].routeName
      : "InboxScreen"

  const padding = theme.padding * (2 / 3)

  const tabContainerStyle = {
    display: "flex",
    flexDirection: isMobile ? "row" : "column",
    width: isMobile ? "100%" : 80,
    height: isMobile ? "auto" : "100%",
    padding,
    paddingBottom: isMobile ? padding + getStatusBarHeight() : 0,
    paddingTop: !isMobile ? padding + getStatusBarHeight() : padding,
    justifyContent: isMobile ? "space-around" : "flex-start",
    alignItems: isMobile ? "center" : "center"
  }
  const tabStyle = {
    marginBottom: isMobile ? 0 : theme.margin
  }

  const renderInbox = () => {
    return (
      <Container
        style={{
          width: "auto",
          maxWidth: 320,
          zIndex: 10,
          borderRightWidth: theme.borderWidth,
          borderRightColor: theme.palette.border
        }}
      >
        <InboxView />
      </Container>
    )
  }

  return (
    <Container
      surface={!isMobile}
      style={{
        flex: 1,
        display: "flex",
        flexDirection: isMobile ? "column" : "row"
      }}
    >
      {/* {!isMobile ? renderTabs() : null} */}
      {!isMobile && profile ? renderInbox() : null}
      <Container
        transparent
        style={{
          flexGrow: 1,
          width: "auto",
          maxWidth: "100%"
        }}
      >
        <ActiveScreen navigation={descriptor.navigation} />
      </Container>
      {/* {isMobile ? renderTabs() : null} */}
    </Container>
  )
}

const createSwitchTabsNavigator = (routeConfigMap, sidebarNavigatorConfig) => {
  const customTabRouter = TabRouter(routeConfigMap, sidebarNavigatorConfig)
  return createNavigator(TabsNavigator, customTabRouter, {})
}

export default createSwitchTabsNavigator
