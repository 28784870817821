import React, { useContext, useEffect, useState, useCallback } from "react"
import PROFILES_QUERY from "../graphql/queries/profiles-query"
import { useQuery, useLazyQuery } from "@apollo/react-hooks"
import useMe from "./useMe"

const PeopleContext = React.createContext()

const _usePeople = () => {
  const profile = useMe()
  const [
    queryProfiles,
    { data, loading, called, error, refetch }
  ] = useLazyQuery(PROFILES_QUERY, { fetchPolicy: "cache-and-network" })

  useEffect(() => {
    if (profile && profile.id && !loading && !called) {
      queryProfiles()
    }
  }, [profile, loading, called])

  // useEffect(() => {
  //   if (data && data.profiles) {
  //     setPeople(data.profiles)
  //   }
  // }, [data, loading, error])

  const selectProfile = id => {
    if (!data) return null
    return useCallback(data.profiles.find(p => p.id === id), [data])
  }

  const people = data && data.profiles ? data.profiles : []
  return { people, loading, error, selectProfile, refetch }
}

export const usePeople = () => {
  return useContext(PeopleContext)
}

export const PeopleProvider = ({ children }) => {
  const value = _usePeople()
  return (
    <PeopleContext.Provider value={value}>{children}</PeopleContext.Provider>
  )
}

export default usePeople
