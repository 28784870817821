import React, { useState } from "react"
import {
  View,
  TouchableHighlight,
  TouchableWithoutFeedback
} from "react-native"
import { LinearGradient } from "expo-linear-gradient"
import { useTheme } from "../../../theme"
import { TextSecondary, Container } from "../../ui"
import { lerpArray } from "../../../utils/lerp"
import Point from "../../ui/TouchPoint"
import { getScaledValue } from "../../thread/scale"
import hapticImpact from "../../../utils/hapticImpact"

const MultiPointInput = ({
  point,
  value: initialValue,
  barHeight,
  onChange
}) => {
  const { scale } = point
  const [theme] = useTheme()
  const [size, setSize] = useState()
  const [value, setValue] = useState(initialValue ? initialValue.value : 0)
  const [points, setPoints] = useState(
    initialValue && initialValue.data.touches
      ? initialValue.data.touches.map(t => ({ ...t, animate: false }))
      : []
  )

  const addPoint = async (x, y, scaleValue) => {
    const value = x / size.width
    const nextPoints = [...points, { position: { x, y }, value, animate: true }]
    const sum = nextPoints.reduce((sum, p) => sum + p.value, 0)
    const avg = sum / nextPoints.length

    const sv = getScaledValue(scale, value)

    const impactStyle = lerpArray(["light", "medium", "heavy"], value)
    await hapticImpact(impactStyle)

    setValue(avg)
    setPoints(nextPoints)
    onChange({
      key: point.id,
      id: point.id,
      label: point.label,
      quantitativeValue: avg,
      qualitativeValue: scaleValue.label,
      data: { touches: nextPoints }
    })
  }

  const renderGradient = () => {
    // if (!value) return null
    // const startColor = new Color(baseColor).alpha(value ? 0.5 : 0.3).toString()
    const colors = [
      theme.palette.background,
      theme.palette.accent,
      theme.palette.background
    ]
    const center = value ? value : 0.5
    return (
      <LinearGradient
        pointerEvents="none"
        colors={colors}
        start={[0, 0]}
        end={[1, 0]}
        locations={[0, center, 1]}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          opacity: value ? 0.7 : 0.3,
          borderBottomLeftRadius: theme.borderRadius,
          borderTopLeftRadius: theme.borderRadius,
          borderBottomRightRadius: theme.borderRadius,
          borderTopRightRadius: theme.borderRadius,
          width: "100%",
          height: size.height
        }}
      />
    )
  }

  const renderScale = () => {
    const getClosestScale = () => {
      const goal = value
      return scale.reduce((prev, curr) => {
        if (!prev) return curr
        return Math.abs(curr.value - goal) < Math.abs(prev.value - goal)
          ? curr
          : prev
      })
    }

    const closest = value ? getClosestScale() : null

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: theme.margin
        }}
      >
        {scale.map(s => (
          <TextSecondary
            style={{
              textAlign: "center",
              paddingVertical: theme.padding / 2,
              width: size
                ? size.width / scale.length
                : `${(1 / scale.length) * 100}%`,
              fontWeight: s === closest ? "bold" : "normal"
            }}
            key={s.label}
          >
            {s.label}
          </TextSecondary>
        ))}
      </View>
    )
  }

  const renderPoints = () => {
    return points.map((point, i) => {
      return <Point key={i} {...point} />
    })
  }

  const renderTouchPads = () => {
    // TODO: render a touch pad (w/ highlight) for each scale.
    // TODO: use the touch position to determin the lerped sclae for each pad
    const w = size.width / scale.length
    const handleTouch = index => e => {
      e.preventDefault()
      e.stopPropagation()
      const x = e.nativeEvent.locationX - size.x
      const y = e.nativeEvent.locationY - size.y

      const scaleValue = scale[index].value
      const perc = x / w

      addPoint(w * index + x, y, {
        index,
        perc,
        label: scale[index].label,
        value: scaleValue
      })
    }

    return (
      <View
        style={{
          width: size.width,
          height: size.height,
          display: "flex",
          flexDirection: "row"
        }}
      >
        {scale.map((s, i) => {
          let borderStyles = {}
          if (i === 0)
            borderStyles = {
              borderBottomLeftRadius: theme.borderRadius,
              borderTopLeftRadius: theme.borderRadius
            }
          if (i === scale.length - 1)
            borderStyles = {
              borderBottomRightRadius: theme.borderRadius,
              borderTopRightRadius: theme.borderRadius
            }

          return (
            <TouchableHighlight
              activeOpacity={0}
              underlayColor={theme.palette.accent}
              key={`touchpad-${i}`}
              style={{
                width: w,
                height: size.height,
                borderRightWidth:
                  i !== scale.length - 1 ? theme.borderWidth : 0,
                borderColor: theme.palette.accent,
                ...borderStyles
              }}
              onPressIn={handleTouch(i)}
            >
              <View
                style={{
                  width: w,
                  height: size.height
                }}
              />
            </TouchableHighlight>
          )
        })}
      </View>
    )
  }

  return (
    <Container transparent>
      <TouchableWithoutFeedback>
        <View
          onLayout={e => {
            if (!size) {
              setSize(e.nativeEvent.layout)
            }
          }}
          style={{
            overflow: "hidden",
            position: "relative",
            display: "flex",
            borderRadius: theme.borderRadius,
            borderWidth: theme.borderWidth,
            overflow: "hidden",
            width: "100%",
            minWidth: "100%",
            borderColor: theme.palette.accent,
            backgroundColor: theme.palette.background,
            minHeight: barHeight
          }}
        >
          {size ? renderGradient() : null}
          {size ? renderTouchPads() : null}
        </View>
      </TouchableWithoutFeedback>
      {renderScale()}
      {renderPoints()}
    </Container>
  )
}

MultiPointInput.defaultProps = {
  barHeight: 50
}

export default MultiPointInput
