import React from "react"
import Container from "../components/ui/Container"
import pluralize from "pluralize"
import {
  Text,
  Title,
  Card,
  Emoji,
  TextSecondary,
  TitleStack,
  Paragraph,
  Icon
} from "../components/ui"
import usePoints from "../hooks/usePoints"
import { useTheme } from "../theme"
import { Dimensions, TouchableOpacity } from "react-native"
import Modal from "../components/ui/Modal"
import { FlatList } from "react-native-gesture-handler"

const Deck = ({ model, onSelect }) => {
  const [theme] = useTheme()
  return (
    <TouchableOpacity
      onPress={() => onSelect(model.key)}
      style={{
        width: "50%",
        padding: theme.margin
      }}
    >
      <Container
        column
        round
        surface
        pad
        style={{ minHeight: 200, justifyContent: "flex-start" }}
      >
        <Emoji
          emoji={model.emoji}
          size={24}
          style={{ marginRight: theme.margin }}
        />
        {/* <TitleStack sub title={model.label} /> */}
        <Title sub>{model.label}</Title>
        {model.description ? (
          <Paragraph numberOfLines={3}>{model.description}</Paragraph>
        ) : null}
        <Container row transparent>
          <Icon
            icon="circle"
            color={theme.palette.accent}
            style={{
              marginRight: theme.margin / 2,
              marginVertical: theme.margin / 2
            }}
          />
          <Text>{pluralize("point", model.points.length, true)}</Text>
        </Container>
      </Container>
    </TouchableOpacity>
  )
}

const PointDecks = ({ navigation }) => {
  const [theme] = useTheme()
  const { model } = usePoints()

  const handleSelect = pointKey => {
    navigation.navigate("PointDeck", { pointKey })
  }

  return (
    <Modal contentContainerStyle={{}}>
      <FlatList
        numColumns={2}
        columnWrapperStyle={{
          minHeight: 200
        }}
        data={model.points}
        keyExtractor={model => model.key}
        renderItem={({ item }) => {
          return <Deck model={item} onSelect={handleSelect} />
        }}
      />
    </Modal>
  )
}

export default PointDecks
