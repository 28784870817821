import usePoints from "./usePoints"
import { useReducer } from "react"

const feedbackReducer = (state, action) => {
  switch (action.type) {
    case "select_points":
      return { ...state, selectedPoints: action.pointKeys }
    case "set_from":
      return { ...state, from: action.from }
    case "set_to":
      return { ...state, to: action.to }
    case "set_context":
      return { ...state, contextID: action.contextID }
    default:
      return state
  }
}

const useFeedbackState = thread => {
  const defaultState = {
    selectedPoints: [],
    // selectedPoints: ["basic.firstName", "basic.lastName"],
    // selectedPoints: ["wellness.sleep", "wellness.r&r", "wellness.diet"],
    from: thread.from,
    to: thread.to,
    contextID: null
  }

  const { selectPoint } = usePoints()
  const [state, dispatch] = useReducer(feedbackReducer, defaultState)

  // map point keys to models
  const selectedPoints = state.selectedPoints.map(id => selectPoint(id))

  const setFrom = from => dispatch({ type: "set_from", from })
  const setTo = to => dispatch({ type: "set_to", to })
  const setContext = contextID => dispatch({ type: "set_context", contextID })
  const selectPoints = pointKeys =>
    dispatch({ type: "select_points", pointKeys })

  return {
    selectedPoints,
    from: state.from,
    to: state.to,
    contextID: state.contextID,
    actions: { setFrom, setTo, setContext, selectPoints }
  }
}

export default useFeedbackState
