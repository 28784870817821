import React from "react"
import { useTheme } from "../../theme"
import { Container, Name, Avatar } from "../ui"
import { TouchableOpacity } from "react-native"
import { useNavigation } from "react-navigation-hooks"

const PeopleItem = ({ profile, onSelect }) => {
  const [theme] = useTheme()
  const handleSelect = () => {
    onSelect(profile)
  }
  return (
    <TouchableOpacity onPress={handleSelect} style={{ width: "50%" }}>
      <Container pad row style={{ alignItems: "center" }}>
        <Avatar profile={profile} style={{ marginRight: theme.margin }} />
        <Name full title sub profile={profile} />
      </Container>
    </TouchableOpacity>
  )
}

export default PeopleItem
