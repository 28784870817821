import useAppState from "./useAppState"
import { useEffect } from "react"
import { useNavigation } from "react-navigation-hooks"

const useMe = () => {
  const { state } = useAppState()

  // const navigation = useNavigation()

  // useEffect(() => {
  //   console.log("useMe effect:", !!state.profile)
  //   if (!state.profile) {
  //     console.log("use me redirecting...")
  //     navigation.navigate("Authenticate")
  //   }
  // }, [state.profile])

  return state.profile
}

export default useMe
