import React from "react"
import { Animated } from "react-native"
import { TextSecondary } from "../../ui/Text"
import { useTheme } from "../../../theme"
import useAnimation from "../../../hooks/useAnimation"

const SentLabel = ({ message }) => {
  const [theme] = useTheme()
  const animate = new Date().getTime() - parseInt(message.clientTime) < 2000
  const { lerp } = useAnimation({ duration: animate ? 300 : 0 })
  return (
    <Animated.View
      style={{ opacity: lerp([0, 1]), transform: [{ scale: lerp([0.5, 1]) }] }}
    >
      <TextSecondary style={{ marginTop: theme.margin / 2 }}>
        Sent
      </TextSecondary>
    </Animated.View>
  )
}

export default SentLabel
