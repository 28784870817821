export const getThreadId = ({ to, from }) => {
  const ids = [to.id, from.id].sort((a, b) => (a > b ? 1 : -1))
  return `${ids[0]}-${ids[1]}`
}

export const selectNewThread = ({ to, from }) => {
  const id = getThreadId({ from, to })
  return {
    id,
    messages: [],
    from,
    to,
    pointCount: 0
  }
}

export const threadsReducer = (state, message) => {
  // if (!isMessage(message)) return state

  if (message.type === "new_thread") {
    const nextThreads = {
      ...state.threads,
      [message.thread.id]: message.thread
    }
    return { ...state, threads: nextThreads }
  }

  if (message.type === "mark_read") {
    const thread = { ...state.threads[message.threadId], unreadCount: 0 }
    thread.messages = thread.messages.map(m => ({
      ...m,
      read: new Date(message.clientReadTime)
    }))
    const nextThreads = { ...state.threads, [message.threadId]: thread }
    return { ...state, threads: nextThreads }
  }

  if (!message.threadId) return state

  const id = message.threadId
  const nextThreads = { ...state.threads }
  if (!nextThreads[id]) {
    // This is a new thread
    nextThreads[id] = {
      id,
      from: message.from,
      to: message.to,
      unreadCount: 0,
      messages: [],
      lastMessage: null,
      lastUpdated: null,
      pointCount: 0
    }
  }

  const clientMessageIndex = nextThreads[id].messages.findIndex(
    m => m.clientTime === message.clientTime
  )

  if (clientMessageIndex < 0) {
    nextThreads[id].messages.push(message)
    nextThreads[id].pointCount += message.points ? message.points.length : 0
    if (message.type === "feedback" && !!message.context) {
      // Handle messages tied to a specific request message
      const nextMessages = [...nextThreads[id].messages]
      const parentMessage = nextMessages.find(m => m.id === message.context)
      if (!parentMessage.progress) parentMessage.pointProgress = {}
      parentMessage.pointProgress[message.data.key] = message.data.value
      parentMessage.progress =
        Object.values(parentMessage.pointProgress).length /
        parentMessage.data.points.length
      nextThreads[id] = { ...nextThreads[id], messages: nextMessages }
    }
  } else {
    nextThreads[id].messages[clientMessageIndex] = message
  }
  nextThreads[id].lastMessage = message
  nextThreads[id].unreadCount +=
    message.read === null && message.me && message.me.id === message.to.id
      ? 1
      : 0
  nextThreads[id].lastUpdated = message.sent
    ? message.sent
    : new Date().toString()
  return { ...state, lastMessageSentDate: message.sent, threads: nextThreads }
}
