import React, { useState } from "react"
import { Animated } from "react-native"
import { NavigationEvents } from "react-navigation"
import useAnimation from "../../hooks/useAnimation"
import ActionButton from "./ActionButton"
import Container from "./Container"
import { useTheme } from "../../theme"

const AnimatedActionButton = props => {
  const [theme] = useTheme()
  // Button that animates in/out when the screen focuses
  const [transitionIn, setTransitionIn] = useState(true)
  const { lerp } = useAnimation({ duration: 200 }, transitionIn)
  const offset = props.offset || 100
  const start = transitionIn ? offset : 0
  const end = transitionIn ? 0 : offset
  const feedbackOffset = lerp([start, end])

  const handleWillFocus = () => {
    setTransitionIn(true)
  }

  const handleWillBlur = () => {
    setTransitionIn(false)
  }

  return (
    <Container
      transparent
      row
      pointerEvents="box-none"
      center
      style={{
        justifyContent: "center",
        paddingRight: theme.padding,
        position: "absolute",
        bottom: theme.margin * 2,
        zIndex: 2
      }}
    >
      <Animated.View
        style={{
          transform: [{ translateY: feedbackOffset }]
        }}
      >
        <NavigationEvents
          onWillFocus={handleWillFocus}
          onWillBlur={handleWillBlur}
        />
        <ActionButton {...props} />
      </Animated.View>
    </Container>
  )
}

export default AnimatedActionButton
