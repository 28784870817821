import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { from, split } from "apollo-link"
import { HttpLink } from "apollo-link-http"
import { onError } from "apollo-link-error"
import { setContext } from "apollo-link-context"
import { WebSocketLink } from "apollo-link-ws"
import { RetryLink } from "apollo-link-retry"
import { getMainDefinition } from "apollo-utilities"
import SyncStorage from "../utils/SyncStorage"

// TODO: handle staging...
const env = !__DEV__ || !!process.env.GQL_HOST ? "production" : "dev"

export const TOKEN_STORAGE_KEY = `authToken.0.0.3`
export const SPACE_STORAGE_KEY = `spaceId.0.0.3`

const DEBUG = false

const getGraphQLUri = () => {
  if (env === "production") {
    const host = process.env.GQL_HOST || "api.pointalyst.com"
    return `https://${host}/graphql`
  }
  return "http://localhost:8000/graphql"
}

const getWSLink = () => {
  const protocol = env === "production" ? "wss://" : "ws://"
  const wsUri = `${protocol}${getGraphQLUri().split("://")[1]}`
  // console.log(SyncStorage.getItem(TOKEN_STORAGE_KEY))
  return new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true,
      connectionParams: () => {
        return {
          Authorization: `Bearer ${SyncStorage.getItem(TOKEN_STORAGE_KEY)}`,
          spaceId: SyncStorage.getItem(SPACE_STORAGE_KEY)
        }
      }
    }
  })
}

const clients = new Map()
export const getClient = spaceId => {
  if (!clients.has(spaceId)) {
    const retryLink = new RetryLink()
    const cache = new InMemoryCache()
    const wsLink = getWSLink()
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        })
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        console.log(networkError)
      }
    })

    const httpLink = new HttpLink({
      uri: getGraphQLUri(),
      fetch: (...pl) => {
        if (!DEBUG) return fetch(...pl)
        const [_, options] = pl
        const body = JSON.parse(options.body)
        if (body.query)
          console.log(
            `📡${body.operationName || ""}\n${body.query}`,
            body.variables
          )
        if (body.mutation)
          console.log(
            `📡${body.operationName || ""}\n${body.mutation}`,
            body.variables
          )
        return fetch(...pl)
      }
    })

    const authMiddleware = setContext(() => {
      const token = SyncStorage.getItem(TOKEN_STORAGE_KEY)
      const storedSpaceId = SyncStorage.getItem(SPACE_STORAGE_KEY)
      const headers = {}
      // console.log(token)
      if (token) headers.Authorization = `Bearer ${token}`
      if (storedSpaceId) headers.spaceId = storedSpaceId
      // console.log(headers)
      if (DEBUG) console.log(headers)
      return { headers }
    })

    const webLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        )
      },
      wsLink,
      httpLink
    )
    clients.set(
      spaceId,
      new ApolloClient({
        cache,
        assumeImmutableResults: false,
        link: from([errorLink, retryLink, authMiddleware, webLink])
      })
    )
  }
  return clients.get(spaceId)
}
