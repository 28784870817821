import { useState, useEffect, useCallback } from "react"
import { useMutation } from "@apollo/react-hooks"
import AUTH_WITH_LINK from "../graphql/mutations/auth-with-link"
import AUTH_WITH_PIN from "../graphql/mutations/auth-with-pin"
import { TOKEN_STORAGE_KEY, SPACE_STORAGE_KEY } from "../graphql"
import SyncStorage from "../utils/SyncStorage"
import useAppState from "./useAppState"

const useAuthLink = () => {
  const [finished, setFinished] = useState(false)
  const [authWithMagicLink, authWithLinkResult] = useMutation(AUTH_WITH_LINK, {
    onCompleted: data => {
      SyncStorage.setItem(TOKEN_STORAGE_KEY, data.authWithMagicLink.token)
      SyncStorage.setItem(SPACE_STORAGE_KEY, data.authWithMagicLink.space.id)
      setFinished(true)
    },
    onError: error => {
      setFinished(true)
    },
    fetchPolicy: "no-cache"
  })
  return [authWithMagicLink, { ...authWithLinkResult, finished }]
}

export const useAuthPin = pin => {
  const [finished, setFinished] = useState(false)
  const [pinError, setPinError] = useState()

  const [authWithPin, { loading, data }] = useMutation(AUTH_WITH_PIN, {
    onCompleted: data => {
      SyncStorage.setItem(TOKEN_STORAGE_KEY, data.authWithPin.token)
      SyncStorage.setItem(SPACE_STORAGE_KEY, data.authWithPin.space.id)
      setFinished(true)
    },
    onError: error => {
      setFinished(true)
      setPinError(error)
    },
    fetchPolicy: "no-cache"
  })

  return [authWithPin, { loading, finished, data, error: pinError }]
}

export default useAuthLink
