import gql from "graphql-tag"

export default gql`
  fragment ProfileFragment on Profile {
    id
    name
    surname
    email
    avatarUri
    snapshot
  }
`
