export const getScaleFromInput = (scale, input) => {
  const index = Math.floor(input * scale.length)
  return scale[index]
}

export const getColor = (benchmark, value) => {}

export const getScaledValue = (scale, input) => {
  // TODO: in the future possibly lerp for non-linear scales
  return input
}
