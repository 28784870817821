import React from "react"
import IconButton from "./IconButton"
import { useTheme } from "../../theme"

const ActionButton = ({ icon, style, containerStyle, onPress }) => {
  const [theme] = useTheme()
  return (
    <IconButton
      primary
      size={65}
      iconSize={26}
      icon={icon}
      onPress={onPress}
      style={{
        borderWidth: theme.margin / 3,
        borderColor: theme.palette.background,
        ...style
      }}
    />
  )
}

export default ActionButton
