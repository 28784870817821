import React from "react";
import Color from "color";
import { View, SafeAreaView, TouchableOpacity, ScrollView } from "react-native";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { useTheme } from "../../theme";
import { BlurView } from "expo-blur";

const Container = ({
  children,
  full,
  style,
  safe,
  surface,
  pad,
  round,
  row,
  center,
  fade,
  transparent,
  shadow,
  column,
  blur,
  debug,
  scroll,
  border,
  ...props
}) => {
  let ViewComponent = safe ? SafeAreaView : View;
  if (blur) ViewComponent = BlurView;
  if (scroll) ViewComponent = ScrollView;

  const [theme] = useTheme();

  let backgroundColor = surface
    ? theme.palette.surface
    : theme.palette.background;
  if (fade) {
    backgroundColor = new Color(backgroundColor).alpha(0.5).toString();
  }
  if (transparent || blur) backgroundColor = "transparent";

  const padding = pad ? theme.padding : 0;
  const borderRadius = round ? theme.borderRadius : 0;

  let styles = {
    backgroundColor,
    padding,
    borderRadius,
    width: "100%",

    position: "relative",
    borderColor: theme.palette.border
  };
  if (shadow)
    styles = {
      ...styles,
      shadowColor: theme.palette.shadow,
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: theme.shadowRadius,
      shadowOpacity: theme.shadowOpacity
    };

  if (full) styles.flex = 1;

  if (border)
    styles = {
      ...styles,
      borderWidth: theme.borderWidth,
      borderColor: theme.palette.border
    };

  if (row)
    styles = {
      ...styles,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "nowrap"
    };
  if (column)
    styles = {
      ...styles,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexWrap: "nowrap"
    };
  if (center)
    styles = { ...styles, justifyContent: "center", alignItems: "center" };
  if (debug) styles = { ...styles, borderWidth: 0.6, borderColor: "red" };

  // if (safe)
  //   styles = {
  //     ...styles,
  //     paddingTop: (pad ? theme.padding : 0) + getStatusBarHeight()
  //   }

  const blurProps = blur ? { tint: "dark", intensity: 100 } : {};
  const passProps = {
    ...props,
    [scroll ? "contentContainerStyle" : "style"]: { ...styles, ...style },
    ...blurProps
  };

  return <ViewComponent {...passProps}>{children}</ViewComponent>;
};

export default Container;
