import React, { useContext, useState, useEffect, useCallback } from "react"
import useAppState from "./useAppState"

const PointsContext = React.createContext()

export const parseModel = model => {
  // Flattens a model and assigns appropriate keys, returns a flat list and a map
  const pointsByID = {}
  const flattenPoints = (points, root) => {
    return points.reduce((pts, pt, index) => {
      if (pt.points) {
        const childPoints = flattenPoints(pt.points, root || pt)
        pts = pts.concat(childPoints)
        pt.id = pt.id || pt.key
        pt.order = pt.order || index + 1
        pointsByID[pt.id] = {
          id: pt.id,
          key: pt.key,
          label: pt.label,
          emoji: pt.emoji,
          points: childPoints
        }
      } else {
        const id = root ? `${root.key}.${pt.key}` : pt.key
        if (!pt.type) pt.type = "MultiPointInput"
        pt.id = id
        pt.order = pt.order || index + 1
        pt.parent = {
          id: root.id,
          key: root.key,
          label: root.label,
          emoji: root.emoji
        }
        pts.push(pt)
        if (typeof pt.scale === "string") {
          const scaleLabels = pt.scale.split(",").map(l => l.trim())
          pt.scale = scaleLabels.map((label, i) => ({
            label,
            value: [0, 0.5, 1][i]
          }))
        }
        pointsByID[pt.id] = pt
      }
      return pts
    }, [])
  }
  model.points = model.points.sort((a, b) => (a.order > b.order ? 1 : -1))
  model.allPoints = flattenPoints(model.points, null)
  model.pointsByID = pointsByID
  return model
}

const _usePoints = () => {
  // TODO: replace this with a GQL query (e.g. LOAD_MODELS)
  const {
    state: { spaceId, space }
  } = useAppState()
  const [model, setModel] = useState()

  useEffect(() => {
    if (space) {
      const parsedModel = parseModel({
        points: Object.values(space.model)
      })
      setModel(parsedModel)
    }
  }, [space])
  // console.log("space state:", state)
  // console.log("space model: ", space.model)
  // const model = parseModel({ points })
  const selectPoint = useCallback(
    id => {
      if (model && !model.pointsByID[id]) {
        console.error(
          "no point w/ id found!",
          id,
          Object.keys(model.pointsByID)
        )
      }
      return model.pointsByID[id]
    },
    [model, spaceId]
  )
  return { model, selectPoint }
}

export const usePoints = () => {
  return useContext(PointsContext)
}

export const PointsProvider = ({ children }) => {
  const state = _usePoints()
  return (
    <PointsContext.Provider value={state}>{children}</PointsContext.Provider>
  )
}

export default usePoints
