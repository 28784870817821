import React, { useEffect, useCallback } from "react"
import { IconButton, ErrorMessage, Loading } from "../components/ui"
import { useTheme } from "../theme"
import Container from "../components/ui/Container"
import Header from "../components/ui/Header"
import { useLocalization } from "../localization"
import AnimatedActionButton from "../components/ui/AnimatedActionButton"
import useMe from "../hooks/useMe"
import Threads from "../components/thread/Threads"
import useDimensions from "../hooks/useDimensions"
import useMessages from "../hooks/useMessages"
import { useNavigation } from "react-navigation-hooks"

export const InboxView = ({ style }) => {
  const profile = useMe()
  const [theme] = useTheme()
  const { isMobile } = useDimensions()
  const { i18n } = useLocalization()
  const navigation = useNavigation()

  const handleSelectThread = thread => {
    navigation.navigate("Thread", { threadId: thread.id })
  }

  const handleRenderActions = () => {
    return (
      <>
        <IconButton
          icon="book"
          style={{ marginRight: theme.margin }}
          onPress={() => navigation.navigate("PointDecks")}
        />
        <IconButton
          icon="settings"
          onPress={() => navigation.navigate("Settings")}
        />
      </>
    )
  }

  return (
    <Container full style={style}>
      <Header
        title={i18n.t("inbox.title")}
        // title={profile.name}
        profile={profile}
        align="left"
        back={false}
        renderActions={handleRenderActions}
      />
      {/* <People /> */}
      <Threads onSelectThread={handleSelectThread} profile={profile} />
      <AnimatedActionButton
        icon="send"
        onPress={() => navigation.navigate("NewFeedback")}
      />
    </Container>
  )
}

const Inbox = ({ navigation }) => {
  const profile = useMe()

  useEffect(() => {
    if (!profile || !profile.id) {
      navigation.navigate("Authenticate")
    }
  }, [profile])

  return <InboxView navigation={navigation} />
}

const ThreadRedirect = ({ navigation }) => {
  // Redirects to the first thread
  const {
    state: { loading, error, threads }
  } = useMessages()
  useEffect(() => {
    if (threads && threads.length) {
      navigation.navigate("Thread", { threadId: threads[0].id })
    }
  }, [loading, error, threads])

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />

  return <Container full></Container>
}

const InboxContainer = ({ navigation }) => {
  const { isMobile } = useDimensions()
  return isMobile ? (
    <Inbox navigation={navigation} />
  ) : (
    <Container full center>
      <Loading />
    </Container>
  )
}

export default InboxContainer
