import { useEffect, useState, useRef } from "react"
import { Animated, Easing } from "react-native"

const useAnimation = (options = {}, flag) => {
  const [animating, setAnimating] = useState(true)
  let value = useRef(new Animated.Value(0))

  // useEffect(() => {
  //   value = useRef(new Animated.Value(0))
  //   setAnimating(true)
  // }, flag)

  useEffect(
    () => {
      if (!animating) {
        value.current = new Animated.Value(0)
        setAnimating(true)
      }
      Animated.timing(value.current, {
        toValue: 1,
        duration: options.duration || 300,
        ...options
      }).start(done => {
        if (done.finished) {
          setAnimating(false)
          if (typeof options.onFinish === "function") options.onFinish()
        }
      })
    },
    flag !== undefined ? [flag] : []
  )

  const lerp = (outputRange, easingFn) => {
    const ops = {
      inputRange: [0, 1],
      outputRange
    }
    if (easingFn && !options.useNativeDriver) ops.easing = easingFn
    return value.current.interpolate(ops)
  }

  return { value: value.current, animating, lerp }
}

export default useAnimation
