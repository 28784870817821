import React, { useEffect, useContext, useReducer } from "react"
import { useQuery, useMutation, useSubscription } from "@apollo/react-hooks"
import MESSAGES_QUERY from "../graphql/queries/messages-query"
import useAppState from "./useAppState"
import SEND_MESSAGE_MUTATION from "../graphql/mutations/send-message"
import MESSAGES_SUBSCRIPTION from "../graphql/subscriptions/messages-subscription"
import MARK_THREAD_AS_READ_MUTATION from "../graphql/mutations/mark-thread-as-read"
import { threadsReducer, getThreadId } from "../components/thread/state"
import reduceReducers from "../utils/reduceReducers"

const initialState = {
  loading: false,
  error: null,
  loaded: false,
  lastMessageSentDate: null,
  messages: [],
  threads: {}
}

export const isMessage = m => !!m.threadId

export const syncMessage = (messages, message) => {
  // Replace any local messages w/ synced clientTime from server
  let foundLocalMessage = false
  const nextMessages = [...messages].map(m => {
    foundLocalMessage = m.clientTime === message.clientTime || foundLocalMessage
    return m.clientTime === message.clientTime ? message : m
  })
  if (!foundLocalMessage) nextMessages.push(message)
  return nextMessages
}

const syncStateReducer = (state, action) => {
  switch (action.type) {
    case "set_loading":
      return { ...state, loading: action.value }
    case "set_loaded":
      return { ...state, loaded: action.value }
    case "set_error":
      return { ...state, error: action.value }
    case "reset":
      return { ...initialState }
    default:
      return state
  }
}

const rootReducer = reduceReducers(threadsReducer, syncStateReducer)

const MessagesContext = React.createContext(initialState)

const _useMessages = () => {
  // can't do useMe here because of navigation context??
  const {
    state: { profile, spaceId }
  } = useAppState()

  const { loading, data, error, refetch } = useQuery(MESSAGES_QUERY, {
    fetchPolicy: "cache-and-network"
  })
  const messagesSubscription = useSubscription(MESSAGES_SUBSCRIPTION)
  useEffect(() => {
    if (messagesSubscription.data && messagesSubscription.data.newMessage) {
      dispatch({ ...messagesSubscription.data.newMessage, me: profile })
    }
  }, [messagesSubscription.data])

  const [sendMessageMutation] = useMutation(SEND_MESSAGE_MUTATION, {
    ignoreResults: true
    //refetchQueries: ["Profile", "ProfileActivity", "ProfileStats"] // refreshes the stats
  })
  const [state, dispatch] = useReducer(rootReducer, initialState)

  const [markThreadAsRead] = useMutation(MARK_THREAD_AS_READ_MUTATION, {})

  const reload = async () => {
    // dispatch({ type: "set_loading", value: true })
    refetch()
    // handleQueryData({ ...result, loading: false })
  }

  const handleQueryData = ({ data, error, loading }) => {
    if (data && !loading) {
      // console.log("loaded messages: ", data.messages.length, data.messages[0])
      data.messages.forEach(m => dispatch({ ...m, me: profile }))
      dispatch({ type: "set_loaded", value: true })
      dispatch({ type: "set_loading", value: false })
      dispatch({ type: "set_error", value: null })
    }

    if (error) {
      dispatch({ type: "set_loaded", value: false })
      dispatch({ type: "set_error", value: error })
      dispatch({ type: "set_loading", value: false })
    }
  }

  useEffect(() => {
    handleQueryData({ data, error, loading })
  }, [loading, data, error])

  useEffect(() => {
    if (!profile && state.loaded) {
      dispatch({ type: "reset" })
    }
    if (profile && !state.loaded && !state.loading) {
      reload()
    }
  }, [profile, loading, state.loaded])

  useEffect(() => {
    if (spaceId && state.loaded) {
      dispatch({ type: "reset" })
    }
  }, [spaceId])

  const sendMessage = (type, to, body, points, data) => {
    const clientTime = `${new Date().getTime()}`
    const optimisticMessage = {
      threadId: getThreadId({ to, from: profile }),
      type,
      to,
      from: profile,
      body,
      points,
      clientTime,
      created: new Date(),
      data,
      _local: true,
      me: profile
    }
    dispatch(optimisticMessage)
    const variables = { type, profileId: to.id, body, points, data, clientTime }
    return sendMessageMutation({
      variables
    })
  }

  const markAsRead = threadId => {
    const clientReadTime = `${new Date().getTime()}`
    dispatch({ type: "mark_read", threadId, clientReadTime })
    markThreadAsRead({ variables: { threadId, clientReadTime } })
  }

  const selectNewThread = ({ from, to }) => {
    const id = getThreadId({ from, to })
    return {
      id,
      messages: [],
      from,
      to,
      pointCount: 0
    }
  }

  const getOrSelectThread = ({ from, to }) => {
    const id = getThreadId({ from, to })
    if (state.threads[id]) {
      return state.threads[id]
    } else {
      const thread = selectNewThread({ from, to })
      dispatch({ type: "new_thread", thread })
      return thread
    }
  }

  state.sortedThreads = Object.values(state.threads).sort((a, b) => {
    return new Date(a.lastUpdated) > new Date(b.lastUpdated) ? -1 : 1
  })

  return {
    state,
    actions: {
      sendMessage,
      getOrSelectThread,
      selectNewThread,
      markAsRead,
      reload
    }
  }
}

export const useMessages = () => {
  return useContext(MessagesContext)
}

export const MessagesProvider = ({ children }) => {
  const state = _useMessages()
  return (
    <MessagesContext.Provider value={state}>
      {children}
    </MessagesContext.Provider>
  )
}

export default useMessages
