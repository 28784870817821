import React, { useEffect, useState } from "react"
import { Container, ErrorMessage, Loading } from "../components/ui"
import useAuthLink from "../hooks/useAuthLink"
import useAppState from "../hooks/useAppState"

const AuthWithLink = ({ navigation }) => {
  const linkId = navigation.getParam("linkId")
  const [hasSetSpace, setHasSetSpace] = useState(false)
  const [authWithMagicLink, { loading, finished, data, error }] = useAuthLink()
  const {
    actions: { setSpace },
    state: { profile }
  } = useAppState()

  useEffect(() => {
    if (!loading && !finished) {
      authWithMagicLink({ variables: { linkId } })
    }
  }, [linkId])

  useEffect(() => {
    if (profile !== null && profile.id) {
      navigation.navigate("Inbox")
    }
  }, [profile])

  useEffect(() => {
    if (finished && data) {
      if (!hasSetSpace) {
        setHasSetSpace(true)
        setSpace(data.authWithMagicLink.space.id)
      }
    }
  }, [finished, data, setSpace, hasSetSpace])

  useEffect(() => {
    if (error) {
      // Gotta try an re-auth
      navigation.navigate("Authenticate")
    }
  }, [error])

  return (
    <Container center full pad safe>
      {loading ? <Loading /> : null}
      {error ? <ErrorMessage error={error} /> : null}
    </Container>
  )
}

export default AuthWithLink
