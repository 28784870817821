import gql from "graphql-tag"
import profileFragment from "./profile-fragment"

export default gql`
  fragment PointFragment on Point {
    id
    key
    created
    quantitativeValue
    qualitativeValue
    toId
    fromId
    data
  }
  ${profileFragment}
`
