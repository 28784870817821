import React, { useState, useEffect, useRef } from "react"
import { View, Animated, Dimensions } from "react-native"
import { useTheme } from "../../../theme"
import { TextInput, IconButton } from "../../ui"
import hapticImpact from "../../../utils/hapticImpact"

const InputSubmit = ({ onSubmit, value, disabled }) => {
  const [theme] = useTheme()
  const [comment, setComment] = useState(value || "")
  const v = useRef(new Animated.Value(0))

  useEffect(() => {
    if (disabled === false) {
      Animated.timing(v.current, { duration: 200, toValue: 1 }).start()
    }
  }, [disabled])

  const handleSubmit = async () => {
    await hapticImpact()
    setComment("")
    onSubmit(comment)
  }

  return (
    <View
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <TextInput
        placeholder="Write a comment..."
        value={comment}
        disabled={disabled}
        autoComplete={true}
        multiline
        style={{
          flexGrow: 1,
          fontSize: 16,
          lineHeight: 16,
          minHeight: 33,
          maxWidth:
            Dimensions.get("screen").width -
            (theme.padding * 4 + theme.margin + 40),
          marginRight: theme.margin
        }}
        onChangeText={text => {
          setComment(text)
        }}
      />
      <IconButton
        onPress={handleSubmit}
        icon="send"
        color={theme.palette.inverseText}
        disabled={disabled}
        style={{
          backgroundColor: !disabled
            ? theme.palette.accent
            : theme.palette.surface,
          borderRadius: "50%",
          width: 40,
          height: 40
        }}
      />
    </View>
  )
}

export default InputSubmit
