import React from "react"
import Container from "../ui/Container"
import usePoints from "../../hooks/usePoints"
import ProfilePointsGroup from "./ProfilePointsGroup"

const ProfilePoints = ({ profile }) => {
  const { model } = usePoints()
  const handleSelectPoint = point => {
    navigation.navigate("ProfilePoint", { profile, pointKey: point.id })
  }
  return (
    <Container row scroll transparent style={{ flexWrap: "wrap" }}>
      {model.points.map(pt => (
        <ProfilePointsGroup
          key={pt.key}
          model={pt}
          profile={profile}
          onSelectPoint={handleSelectPoint}
        />
      ))}
    </Container>
  )
}

export default ProfilePoints
