import gql from "graphql-tag"
import messageFragment from "../fragments/message-fragment"

export default gql`
  query Messages {
    messages {
      ...MessageFragment
    }
  }
  ${messageFragment}
`
