import React, { useState } from "react"
import { useTheme } from "../../theme"
import { Container, Text, Loading, ErrorMessage } from "../ui"
import SearchableFlatList from "../ui/SearchableFlatList"
import SearchInput from "../ui/SearchInput"
import { TouchableOpacity } from "react-native"
import hapticImpact from "../../utils/hapticImpact"
import Avatar from "../ui/Avatar"
import { useLocalization } from "../../localization"
import usePeople from "../../hooks/usePeople"
import useMe from "../../hooks/useMe"
import PeopleItem from "./PeopleItem"

const PeopleSelector = ({ onSelect, profile, profiles, prompt }) => {
  // const [contacts] = useContacts()
  const [searchTerm, setSearchTerm] = useState("")
  const searchablePeople = [...profiles].map(p => ({
    ...p,
    search: `${p.name} ${p.surname}`
  }))

  const handleSelect = person => {
    onSelect(person)
  }
  return (
    <>
      <SearchInput prompt={prompt} onChange={t => setSearchTerm(t)} />
      <SearchableFlatList
        numColumns={2}
        data={searchablePeople}
        searchTerm={searchTerm}
        searchAttribute="search"
        keyExtractor={(item, i) => `${item.key}-${i}`}
        style={{
          height: "100%",
          width: "100%"
        }}
        renderItem={({ item }) => {
          return (
            <PeopleItem profile={item} onSelect={() => handleSelect(item)} />
          )
        }}
      />
    </>
  )
}

const PeopleSelectorContainer = props => {
  const profile = useMe()
  const { people, loading, error } = usePeople()
  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  return <PeopleSelector profile={profile} profiles={people} {...props} />
}

export default PeopleSelectorContainer
