import { useEffect, useState } from "react"
import { AsyncStorage } from "react-native"

const STORAGE_KEY_PREFIX = "ss1.01"

export default (key, defaultValue) => {
  const [storageItem, setStorageItem] = useState(defaultValue)

  const storageKey = `${STORAGE_KEY_PREFIX}:${key}`

  async function getStorageItem() {
    let data = await AsyncStorage.getItem(storageKey)
    // what a horrible hack
    if (data !== null && (data[0] === "{" || data[0] === "["))
      data = JSON.parse(data)
    const value = data || defaultValue
    setStorageItem(value)
  }

  function updateStorageItem(data) {
    if (typeof data === "string") {
      AsyncStorage.setItem(storageKey, data)
      setStorageItem(data)
    } else {
      AsyncStorage.setItem(storageKey, JSON.stringify(data))
      setStorageItem(data)
    }
    return data
  }

  function clearStorageItem() {
    AsyncStorage.removeItem(storageKey)
    setStorageItem(null)
  }

  useEffect(() => {
    getStorageItem()
  }, [])

  return [storageItem, updateStorageItem, clearStorageItem]
}
