import * as React from "react"
import { Dimensions } from "react-native"

const MOBILE_THRESHOLD = 600
const maxWidth = 900

export default () => {
  const [dimensions, setDimensions] = React.useState({
    window: Dimensions.get("window"),
    screen: Dimensions.get("screen"),
    isMobile: Dimensions.get("window").width <= MOBILE_THRESHOLD,
    maxWidth
  })

  const onChange = ({ window, screen }) => {
    setDimensions({
      window,
      screen,
      isMobile: window.width <= MOBILE_THRESHOLD,
      maxWidth
    })
  }

  React.useEffect(() => {
    Dimensions.addEventListener("change", onChange)

    return () => Dimensions.removeEventListener("change", onChange)
  }, [])

  return dimensions
}
