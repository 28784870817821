import React from "react";
import { useTheme } from "../../theme";
import { Container, IconButton } from "../ui";
import { View, Platform, Animated, Dimensions } from "react-native";
import { useNavigation } from "react-navigation-hooks";
import useAnimation from "../../hooks/useAnimation";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import useDimensions from "../../hooks/useDimensions";

const Modal = ({
  children,
  contentContainerStyle,
  useCloseButton,
  loading
}) => {
  const navigation = useNavigation();
  const [theme] = useTheme();
  const { screen } = useDimensions();
  const { lerp } = useAnimation({ delay: 200, duration: 250 });

  const handleClose = () => {
    navigation.goBack();
  };

  const showDragClose = Platform.OS !== "web" && !useCloseButton;

  return (
    <Container full transparent column center>
      <Animated.View
        style={{
          flex: 1,
          position: "absolute",
          top: 0,
          bottom: 0,
          backgroundColor: theme.palette.background,
          opacity: lerp([0, 0.75]),
          width: "100%"
        }}
      />
      {!showDragClose && !loading ? (
        <IconButton
          icon="x"
          onPress={handleClose}
          style={{
            marginBottom: theme.margin,
            borderWidth: theme.borderWidth * 2,
            borderColor: theme.palette.background
          }}
        />
      ) : null}
      <Container
        full
        pad
        round
        column
        style={{
          paddingTop: getStatusBarHeight() + theme.padding * 2,
          ...contentContainerStyle
        }}
      >
        {showDragClose && !loading ? (
          <Container
            transparent
            row
            center
            style={{
              width: "100%",
              maxWidth: screen.width,
              position: "absolute",
              top: getStatusBarHeight()
            }}
          >
            <View
              style={{
                width: 40,
                height: 6,
                borderRadius: 20,
                marginTop: theme.margin,
                marginBottom: theme.margin * 2,
                marginRight: -30,
                backgroundColor: theme.palette.border
              }}
            />
          </Container>
        ) : null}
        {children}
      </Container>
    </Container>
  );
};

Modal.defaultProps = {
  useCloseButton: false,
  scroll: false
};

export default Modal;
