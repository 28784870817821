import React, { useRef } from "react";
import Container from "../components/ui/Container";
import Swiper from "../components/Swiper";

import { Title, Button } from "../components/ui";
import usePoints from "../hooks/usePoints";
import { useTheme } from "../theme";
import Modal from "../components/ui/Modal";
import PointInfoCard from "../components/point/PointInfoCard";
import useDimensions from "../hooks/useDimensions";

const PointDeck = ({ navigation }) => {
  const pointKey = navigation.getParam("pointKey");
  const { maxWidth, screen } = useDimensions();
  const { selectPoint } = usePoints();
  const swiperRef = useRef();
  const point = selectPoint(pointKey);
  const [theme] = useTheme();
  return (
    <Modal>
      <Swiper
        ref={swiperRef}
        cards={point.points}
        renderCard={point => {
          return (
            <Container
              transparent
              row
              center
              pointerEvents={"box-none"}
              style={{
                width: "auto",
                maxWidth: screen.width - theme.padding * 2,
                alignItems: "center",
                marginLeft: theme.padding,
                justifyContent: "center"
              }}
            >
              <PointInfoCard point={point} />
            </Container>
          );
        }}
        onSwiped={cardIndex => {}}
        onSwipedAll={() => {}}
        cardIndex={0}
        backgroundColor={"transparent"}
        stackSize={3}
        marginTop={40}
        onTapCardDeadZone={10}
      >
        <Container transparent style={{ paddingTop: theme.padding * 2 }}>
          <Title sub style={{ textAlign: "center" }}>
            {point.emoji} {point.label}
          </Title>
          <Container center style={{ marginTop: 80 }}>
            <Button
              title="Restart"
              icon="repeat"
              onPress={() => swiperRef.current.jumpToCardIndex(0)}
            />
            <Button link title="Done" onPress={() => navigation.goBack()} />
          </Container>
        </Container>
      </Swiper>
    </Modal>
  );
};

export default PointDeck;
