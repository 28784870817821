import gql from "graphql-tag"
import messageFragment from "../fragments/message-fragment"

export default gql`
  subscription MessagesSubscription {
    newMessage {
      ...MessageFragment
    }
  }
  ${messageFragment}
`
