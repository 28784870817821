import React from "react"
import { TouchableOpacity, View } from "react-native"
import Icon from "../ui/Icon"
import { useTheme } from "../../theme"
import { Text, TextSecondary } from "./Text"
import { withNavigation } from "react-navigation"
import hapticImpact from "../../utils/hapticImpact"
import Emoji from "./Emoji"
import Container from "./Container"

const IconButton = ({
  icon,
  disabled,
  background,
  onPress,
  color,
  iconSize,
  size,
  primary,
  emoji,
  label,
  labelStyle,
  style,
  route,
  navigate,
  navigation,
  containerStyle
}) => {
  const [theme] = useTheme()

  const handlePress = async e => {
    await hapticImpact()
    if (route) navigation.navigate(route)
    if (typeof navigate === "function") {
      const [route, params] = navigate()
      navigation.navigate(route, params)
    }
    if (onPress) onPress(e)
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={handlePress}
      style={containerStyle}
    >
      <Container
        center
        column
        style={{
          width: label ? "auto" : size,
          height: label ? "auto" : size,
          flexGrow: 0,
          backgroundColor:
            background || primary
              ? primary
                ? theme.palette.accent
                : theme.palette.surface
              : "transparent",
          borderRadius: "50%",
          ...style
        }}
      >
        {icon ? (
          <Icon
            size={iconSize}
            color={primary ? theme.palette.inverseText : color}
            icon={icon}
            disabled={disabled}
          />
        ) : null}
        {emoji ? <Emoji emoji={emoji} /> : null}
        {label ? (
          <TextSecondary
            style={{
              textAlign: "center",
              marginTop: theme.margin / 2,
              width: "100%",
              color: color,
              ...labelStyle
            }}
          >
            {label}
          </TextSecondary>
        ) : null}
      </Container>
    </TouchableOpacity>
  )
}

IconButton.defaultProps = {
  background: true,
  size: 40,
  iconSize: 18
}

export default withNavigation(IconButton)
