export const lerp = (v0, v1, t) => {
  return v0 * (1 - t) + v1 * t
}

export const lerpValues = (value1, value2, t, out) => {
  if (typeof value1 === "number" && typeof value2 === "number")
    return lerp(value1, value2, t)
  else {
    //assume array
    var len = Math.min(value1.length, value2.length)
    out = out || new Array(len)
    for (var i = 0; i < len; i++) out[i] = lerp(value1[i], value2[i], t)
    return out
  }
}

export const lerpArray = (arr, v) => {
  const index = Math.floor(lerp(0, arr.length, v))
  return arr[index]
}
