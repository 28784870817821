import React from "react"
import pluralize from "pluralize"
import { View } from "react-native"
import { Title, TextSecondary, Container, Text, Icon, Badge } from "../ui"
import { useTheme } from "../../theme"
import { TouchableOpacity } from "react-native"

import hapticImpact from "../../utils/hapticImpact"
import { useLocalization } from "../../localization"
import usePoints from "../../hooks/usePoints"
import PointFeedback from "../point/PointFeedback"
import Avatar from "../ui/Avatar"

const ThreadItem = ({ thread, me, onSelect, selected }) => {
  const [theme] = useTheme()
  const { selectPoint } = usePoints()
  const { formatDate, i18n } = useLocalization()

  // TODO: figure out why this happens (happens on sign out?)
  if (!me) return null

  const peer = thread.from.id === me.id ? thread.to : thread.from

  const handlePress = async () => {
    await hapticImpact()
    onSelect(thread)
  }

  const renderSubject = () => {
    if (!me) return null
    const withProfile = me.id === thread.from.id ? thread.to : thread.from
    return (
      <Title
        sub
        style={{
          fontSize: theme.fontSize.text
        }}
      >
        {me.id === withProfile.id
          ? "You"
          : `${withProfile.name}${
              withProfile.surname ? ` ${withProfile.surname}` : ""
            }`}
      </Title>
    )
  }

  const renderDate = () => {
    return (
      <Container transparent row style={{ marginLeft: "auto", width: "auto" }}>
        {thread.lastMessage ? (
          <TextSecondary>{formatDate(thread.lastMessage.sent)}</TextSecondary>
        ) : null}
        {thread.unreadCount > 0 ? (
          <Badge
            count={thread.unreadCount}
            highlight
            style={{ marginLeft: theme.margin }}
          />
        ) : null}

        <Icon
          icon="chevron-right"
          color={selected ? theme.palette.accent : theme.palette.textSecondary}
        />
      </Container>
    )
  }

  const renderPointsLabel = () => {
    return (
      <TextSecondary>
        {pluralize(i18n.t("points.label"), thread.pointCount, true)}
      </TextSecondary>
    )
  }

  const renderMessages = () => {
    const renderLastMessage = m => {
      if (m.type === "feedback") {
        const pt = m.points ? m.points[0] : null
        const point = selectPoint(pt.key)
        return (
          <Container
            row
            transparent
            style={{ paddingVertical: theme.padding / 3 }}
          >
            {m.from.id === me.id ? (
              <TextSecondary>{i18n.t("thread.fromSelfPrefix")}</TextSecondary>
            ) : null}
            <PointFeedback
              point={point}
              value={m.points[0]}
              nonInteractive
              size="small"
            />
          </Container>
        )
      } else if (m.type === "feedback_request") {
        return (
          <Container row transparent>
            {m.from.id === me.id ? (
              <Text>{i18n.t("thread.fromSelfPrefix")}: Feedback request</Text>
            ) : null}
          </Container>
        )
      } else if (m.body) {
        return <TextSecondary>{m.body}</TextSecondary>
      }
    }

    return (
      <Container
        row
        transparent
        style={{
          maxWidth: "90%",
          overflow: "hidden"
        }}
      >
        {thread.lastMessage ? renderLastMessage(thread.lastMessage) : null}
      </Container>
    )
  }

  const gutterSize = theme.margin * 2

  return (
    <TouchableOpacity onPress={handlePress}>
      <Container
        row
        style={{
          padding: theme.padding,
          paddingVertical: theme.padding / 3,
          borderBottomWidth: 0, // theme.borderWidth,
          borderBottomColor: theme.palette.accent
        }}
      >
        <Container
          row
          transparent
          style={{
            borderBottomWidth: 0, // theme.borderWidth,
            borderBottomColor: theme.palette.border,
            paddingVertical: theme.padding
          }}
        >
          <Avatar profile={peer} style={{ marginRight: theme.margin }} />
          <Container
            column
            transparent
            style={{
              flex: 1
            }}
          >
            <Container row transparent>
              {renderSubject()}
              {renderDate()}
            </Container>
            {renderMessages()}
          </Container>
        </Container>
      </Container>
    </TouchableOpacity>
  )
}

export default ThreadItem
