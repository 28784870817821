import React, { useState, useRef } from "react"
import InputSubmit from "../thread/FeedbackInput/InputSubmit"
import Swiper from "../Swiper"
import { Container, IconButton, Title, TextSecondary, Paragraph } from "../ui"
import { useTheme } from "../../theme"
import PointInput from "./PointInput"
import PointFeedback from "./PointFeedback"
import useMe from "../../hooks/useMe"
import useDimensions from "../../hooks/useDimensions"
import { Dimensions } from "react-native"

const CARD_HEIGHT = 400
const MAX_CARD_WIDTH = 600

const PointAssessmentCard = ({
  point,
  pointValue,
  toUser,
  onChange,
  index,
  count,
  onSubmit
}) => {
  const isValidValue = v => {
    return (
      v &&
      (v.quantitativeValue || (v.qualitativeValue && v.qualitativeValue.length))
    )
  }

  const [showDetails, setShowDetails] = useState(false)
  const [theme] = useTheme()
  return (
    <Container
      pad
      surface
      round
      shadow
      column
      style={{
        alignItems: "center",
        width: "auto",
        maxWidth: Dimensions.get("screen").width - theme.margin * 2
      }}
    >
      <Container transparent column center>
        {point.parent ? (
          <TextSecondary style={{ marginBottom: theme.margin / 2 }}>
            {point.parent.emoji} {point.parent.label} {index + 1}/{count}
          </TextSecondary>
        ) : null}

        <Title
          style={{
            textAlign: "center",
            marginBottom: point.description ? 0 : theme.margin
          }}
        >
          {point.label}
        </Title>
        {point.description ? (
          <Paragraph style={{ marginTop: 0, textAlign: "center" }}>
            {point.description}
          </Paragraph>
        ) : null}
        {point.details && showDetails ? (
          <Container
            round
            pad
            style={{
              position: "absolute",
              zIndex: 10
            }}
          >
            <IconButton
              icon="x"
              containerStyle={{
                position: "absolute",
                right: -theme.padding,
                top: -theme.padding
              }}
              onPress={() => {
                setShowDetails(false)
              }}
            />

            <Paragraph
              style={{
                fontSize: theme.fontSize.text + 3,
                fontWeight: "bold"
              }}
            >
              “{point.details}”
            </Paragraph>
          </Container>
        ) : null}
        <PointInput
          key={point.id}
          point={point}
          to={toUser.id}
          onChange={onChange}
        />
      </Container>
      <Container transparent column style={{ alignItems: "flex-end" }}>
        <PointFeedback
          point={point}
          value={
            pointValue
              ? pointValue
              : { quantitativeValue: 0, qualitativeValue: "" }
          }
          align={"right"}
          showQualitativeDescriptor={false}
          nonInteractive
        />
        <InputSubmit onSubmit={onSubmit} disabled={!isValidValue(pointValue)} />
      </Container>
    </Container>
  )
}

const PointCardContainer = ({
  point,
  to,
  index,
  count,
  onSubmit,
  onLayout,
  onChange
}) => {
  const [value, setValue] = useState()
  const { isMobile, maxWidth } = useDimensions()

  const handleChange = nextValue => {
    setValue(nextValue)
    if (onChange) onChange(nextValue)
  }

  return (
    <Container
      column
      transparent
      pointerEvents={"box-none"}
      pad
      style={{
        height: CARD_HEIGHT,
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      <PointAssessmentCard
        key={point.id}
        index={index}
        count={count}
        toUser={to}
        point={point}
        onLayout={onLayout}
        pointValue={value}
        onSubmit={message => onSubmit({ ...value, message })}
        onChange={handleChange}
      />
    </Container>
  )
}

const PointAssessment = ({ points, to, onSubmit, onComplete }) => {
  const swipeRef = useRef()
  const [theme] = useTheme()

  const [stackLayout, setStackLayout] = useState({ height: 220, done: true })

  // const currentData = useRef({})
  // const [data, setData] = useState()
  const profile = useMe()

  // const handlePointInput = input => {
  //   const nextData = { ...data, [input.key]: input }
  //   setData(nextData)
  // }

  const handleSubmit = value => {
    swipeRef.current.swipeLeft()
    onSubmit(value)
  }

  const handleComplete = () => {
    onComplete()
  }

  const handleLayout = ({ index, layout }) => {
    // setStackLayout(stackLayout => {
    //   const nextHeight = Math.max(stackLayout.height, layout.height)
    //   const done = index >= 2
    //   console.log("setting stack layout: ", { height: nextHeight, done }, index)
    //   return { height: nextHeight, done }
    // })
  }

  const prefix = to.id === profile.id ? "My" : `${to.name}'s`
  {
    /* <Text style={{ textAlign: "center" }}>
        {point.parent.emoji} {prefix} {point.parent.label} - {index + 1}/
        {points.length}
      </Text> */
  }

  return (
    <Swiper
      ref={swipeRef}
      cards={points}
      disableRightSwipe={true}
      childrenOnTop={true}
      container={{}}
      goBackToPreviousCardOnSwipeRight={false}
      renderCard={(point, i) => {
        return (
          <PointCardContainer
            to={to}
            index={i}
            count={points.length}
            onSubmit={handleSubmit}
            point={point}
            onLayout={handleLayout}
          />
        )
      }}
      // onSwiped={cardIndex => setIndex(cardIndex)}
      overlayLabels={{
        left: {
          element: (
            <Title
              style={{
                color: theme.palette.red,
                padding: theme.padding / 2,
                textShadowColor: theme.palette.backgroundColor,
                textShadowOffset: { width: 0, height: 0 },
                textShadowRadius: 14
                // backgroundColor: theme.palette.surface
              }}
            >
              Skip
            </Title>
          ),
          style: {
            wrapper: {
              position: "absolute",
              width: 100,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // right: -100,
              top: CARD_HEIGHT / 2
            }
          }
        }
      }}
      stackSize={3}
      onSwipedAll={handleComplete}
      cardHorizontalMargin={theme.padding}
      backgroundColor={"transparent"}
      marginBottom={0}
      marginTop={-(80 + theme.padding)}
    ></Swiper>
  )

  // return (
  //   <FlatList
  //     horizontal
  //     data={points}
  //     style={{
  //       maxWidth: "100%",
  //       borderWidth: 1,
  //       borderColor: "red",
  //       overflow: "hidden"
  //     }}
  //     contentContainerStyle={{
  //       paddingVertical: theme.padding,
  //       paddingHorizontal: theme.padding / 2
  //     }}
  //     renderItem={({ item: point, i }) => (
  //       <PointAssessmentCard
  //         key={i}
  //         index={index}
  //         toUser={toUser}
  //         point={point}
  //         pointValue={pointValue}
  //         onSubmit={handleSubmit}
  //         onChange={handlePointInput}
  //       />
  //     )}
  //   />
  // )
}

export default PointAssessment
