import React from "react"
import { TextInput } from "react-native"
import { useTheme } from "../../theme"

export default React.forwardRef(
  (
    { value, placeholder, onChangeText, disabled, style, multiline, ...props },
    ref
  ) => {
    const [theme] = useTheme()
    return (
      <TextInput
        ref={ref}
        value={value}
        placeholder={placeholder}
        onChangeText={onChangeText}
        placeholderTextColor={theme.palette.border}
        multiline={multiline}
        editable={!disabled}
        style={{
          fontSize: theme.fontSize.text,
          minHeight: 40,
          paddingTop: 10,
          paddingBottom: 10,
          opacity: disabled ? 0.666 : 1,
          backgroundColor: theme.palette.background,
          paddingHorizontal: theme.padding,
          color: theme.palette.text,
          borderWidth: theme.borderWidth,
          borderColor: theme.palette.border,
          borderRadius: theme.borderRadius,
          ...style
        }}
        {...props}
      />
    )
  }
)
