import React from "react"
import { ErrorText } from "./Text"
import Container from "./Container"
import Icon from "./Icon"
import { useTheme } from "../../theme"
import { Sentry } from "../../config"

const ErrorMessage = ({ error }) => {
  Sentry.captureException(error)

  const { graphQLErrors, networkError } = error
  console.log(error)
  const [theme] = useTheme()
  let msg = "Oops, something went wrong. Please try again."
  if (graphQLErrors && graphQLErrors.length) {
    const msgs = []
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      msgs.push(message)
    })
    msg = msgs.join(",")
  } else if (networkError) {
    console.log(networkError)
  }

  return (
    <Container pad>
      <Container
        row
        center
        style={{ height: 40, marginVertical: theme.margin }}
      >
        <Icon
          icon="alert-circle"
          color={theme.palette.red}
          style={{ marginRight: theme.margin }}
        />
        <ErrorText>{msg}</ErrorText>
      </Container>
    </Container>
  )
}

export default ErrorMessage
