import React, { useState, useEffect } from "react"
import { KeyboardAvoidingView } from "react-native"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { Container, ErrorMessage, Title, TextInput } from "../components/ui"
import Button from "../components/ui/Button"
import { Linking } from "expo"

const REQUEST_LINK_MUTATION = gql`
  mutation RequestMagicLink($email: String!, $redirectUrl: String!) {
    requestMagicLink(email: $email, redirectUrl: $redirectUrl)
  }
`

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const RequestAuthLink = ({ navigation }) => {
  const [email, setEmail] = useState()
  const [valid, setValid] = useState(false)

  const [requestLink, { loading, data, called, error }] = useMutation(
    REQUEST_LINK_MUTATION
  )

  const handleEmailChange = emailInput => {
    emailInput = emailInput.trim()
    setEmail(emailInput)
    setValid(validateEmail(emailInput))
  }

  const handleRequestLink = () => {
    const redirectUrl = Linking.makeUrl("/")
    requestLink({ variables: { redirectUrl, email } })
  }

  useEffect(() => {
    if (!loading && called) {
      if (!error) {
        navigation.navigate("RequestAuthLinkSuccess", { email })
      }
    }
  }, [data, loading, error])

  return (
    <KeyboardAvoidingView behavior="padding" enabled style={{ flex: 1 }}>
      <Container full safe>
        <Container full pad center>
          <Container
            pad
            column
            center
            style={{ justifyContent: "space-between", minHeight: 180 }}
          >
            <Title>Request Login Code</Title>
            {error ? <ErrorMessage error={error} /> : null}
            <TextInput
              placeholder="Enter your email address"
              onChangeText={handleEmailChange}
              autoCompleteType="email"
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize={"none"}
              style={{ width: "100%" }}
            />
            <Button
              primary
              disabled={!valid || loading === true}
              title={!loading ? "Send Link" : "Sending..."}
              onPress={handleRequestLink}
            />
          </Container>
        </Container>
      </Container>
    </KeyboardAvoidingView>
  )
}

export default RequestAuthLink
