import gql from "graphql-tag"
import messageFragment from "../fragments/message-fragment"

export default gql`
  mutation SendMessage(
    $type: MessageType!
    $profileId: ID!
    $points: [PointInput]
    $body: String
    $data: JSON
    $clientTime: String!
  ) {
    sendMessage(
      type: $type
      body: $body
      points: $points
      data: $data
      clientTime: $clientTime
      to: { profileId: $profileId }
    ) {
      ...MessageFragment
    }
  }
  ${messageFragment}
`
