import gql from "graphql-tag"
import profileFragment from "../fragments/profile-fragment"
import pointFragment from "../fragments/point-fragment"

export default gql`
  query Profile($id: ID!, $withPoints: Boolean!) {
    profile(id: $id) {
      ...ProfileFragment
      points @include(if: $withPoints) {
        ...PointFragment
        from {
          ...ProfileFragment
        }
        to {
          ...ProfileFragment
        }
      }
    }
  }
  ${profileFragment}
  ${pointFragment}
`
