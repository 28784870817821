import React from "react"
import { Text } from "react-native"

const Emoji = ({ emoji, size, style, props }) => {
  return (
    <Text style={{ fontSize: size, ...style }} {...props}>
      {emoji}
    </Text>
  )
}

export default Emoji
