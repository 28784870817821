import React from "react"
import PointMessage from "./PointMessage"

const Feedback = ({ message, justifyContent }) => {
  // for now we're only doing one point per message
  const point = message.points[0]
  return (
    <PointMessage justifyContent={justifyContent} point={point} {...message} />
  )
}

export default Feedback
