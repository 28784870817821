import gql from "graphql-tag"
import profileFragment from "./profile-fragment"
import pointFragment from "./point-fragment"

export default gql`
  fragment MessageFragment on Message {
    id
    threadId
    created
    sent
    read
    clientTime
    from {
      ...ProfileFragment
    }
    to {
      ...ProfileFragment
    }
    type
    body
    data
    points {
      ...PointFragment
    }
  }
  ${profileFragment}
  ${pointFragment}
`
