import React, { useContext, createContext } from "react"
import { iOSColors } from "react-native-typography"
import {
  Appearance,
  AppearanceProvider,
  useColorScheme
} from "react-native-appearance"
import useStorage from "../hooks/useStorage"
import Color from "color"
import { Platform } from "react-native"

const midnight = {
  label: "Midnight",
  key: "midnight",
  borderWidth: 0.3,
  borderRadius: 18,
  shadowRadius: 6,
  shadowOpacity: 0.3,

  padding: 12,
  margin: 10,
  fontSize: {
    heading1: 28,
    heading2: 22,
    text: 16,
    textSecondary: 13
  },
  palette: {
    primary: "#fff",
    green: "#7de043",
    red: "#cf2c28",
    accent: "#49a1eb",
    text: iOSColors.white,
    textSecondary: "#83909c",
    border: "rgba(255,255,255,.3)",
    background: "#17202a",
    surface: "#273340",
    shadow: "#000",
    backgroundSecondary: "#141414",
    inverseText: "#fff"
  }
}

const black = {
  label: "Dark",
  key: "black",
  borderWidth: 0.3,
  borderRadius: 18,
  shadowRadius: 6,
  shadowOpacity: 0.3,

  padding: 12,
  margin: 10,
  fontSize: {
    heading1: 28,
    heading2: 18,
    text: 16,
    textSecondary: 13
  },
  palette: {
    primary: "#fff",
    accent: "#3b82f6",
    text: iOSColors.white,
    green: "#7de043",
    red: "#cf2c28",
    textSecondary: "#b3b2b7",
    border: "#3d3c41",
    background: "#000",
    surface: "#1c1c1e",
    shadow: "#000",
    backgroundSecondary: "#141414",
    inverseText: "#fff"
  }
}

const hip = {
  label: "Hip",
  key: "hip",
  borderWidth: 0.3,
  borderRadius: 30,
  shadowRadius: 6,
  shadowOpacity: 0.3,

  padding: 12,
  margin: 10,
  fontSize: {
    heading1: 28,
    heading2: 22,
    text: 16,
    textSecondary: 13
  },
  palette: {
    primary: "#eee5ff",
    accent: "#9360fb",
    text: "#eee5ff",
    green: "#7de043",
    red: "#cf2c28",
    textSecondary: new Color("#fff").alpha(0.66).toString(),
    border: "rgba(255,255,255,.3)",
    background: "#16131c",
    surface: "#241e2f",
    shadow: "#000",
    backgroundSecondary: "#141414",
    inverseText: "#fff"
  }
}

const light = {
  label: "Light",
  key: "light",
  borderWidth: 0.3,
  borderRadius: 18,
  padding: 12,
  shadowRadius: 6,
  shadowOpacity: 0.3,

  margin: 10,
  fontSize: {
    heading1: 28,
    heading2: 22,
    value: 18,
    text: 16,
    textSecondary: 13
  },
  palette: {
    primary: "#eee5ff",
    accent: "#0b8cde",
    text: "#000",
    green: "#7de043",
    red: "#cf2c28",
    textSecondary: new Color("#000").alpha(0.8).toString(),
    border: "rgba(0,0,0,.3)",
    background: "#fff",
    surface: "#eaeaea",
    shadow: "#000",
    inverseText: "#fff"
  }
}

const gold = {
  ...black,
  label: "Gold",
  key: "gold",
  fontFamily: "HoeflerText-Black",
  palette: {
    ...black.palette,
    text: "#fff",
    accent: "#ffe08b",
    inverseText: "#000"
  }
}

const techno = {
  ...black,
  label: "Techno",
  key: "techno",
  fontFamily: "Menlo-Regular",
  padding: 4,
  margin: 6,
  borderRadius: 4,
  borderWidth: 0.3,
  palette: {
    ...black.palette,
    text: "#fff",
    accent: "#18CAE6",
    inverseText: "#000"
  }
}

export const getSurfaceGradient = theme => {
  return [
    theme.palette.surface,
    new Color(theme.palette.surface).darken(0.3).toString()
  ]
}

export const themes = { midnight, black, hip, light, gold, techno }

const ThemeContext = createContext()

export const ThemeContextProvider = ({ children }) => {
  const colorScheme = useColorScheme()
  const [selectedTheme, setSelectedTheme] = useStorage(
    "theme-key-2",
    colorScheme === "dark" ? "black" : "light"
  )
  const theme = themes[selectedTheme]
  return (
    <ThemeContext.Provider value={[theme, setSelectedTheme]}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = (overrides = {}) => {
  const [theme, selectTheme] = useContext(ThemeContext)
  const props = { ...theme, ...overrides }
  props.borderWidth = Platform.OS === "web" ? 1 : props.borderWidth
  return [props, selectTheme]
}

export const withTheme = Component => {
  return props => {
    const [theme, selectTheme] = useContext(ThemeContext)
    return <Component {...props} theme={theme} />
  }
}
