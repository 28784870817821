import React, { useEffect } from "react"
import { Container, ErrorMessage } from "../components/ui"
import Loading from "../components/ui/Loading"
import useMe from "../hooks/useMe"

const Splash = ({ navigation }) => {
  const linkId = navigation.getParam("linkId")
  const profile = useMe()
  useEffect(() => {
    if (profile) {
      navigation.navigate("Inbox")
    } else if (linkId) {
      navigation.navigate("AuthWithLink", { linkId })
    } else {
      navigation.navigate("Authenticate")
    }
  }, [])

  return (
    <Container full center>
      <Loading />
    </Container>
  )
}
export default Splash
