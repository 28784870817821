import React from "react"
import { View, TouchableOpacity } from "react-native"
import Container from "./Container"
import { Title } from "./Text"
import IconButton from "./IconButton"
import { getStatusBarHeight } from "react-native-iphone-x-helper"
import { withNavigation } from "react-navigation"
import { useTheme } from "../../theme"
import Avatar from "./Avatar"

const Header = ({
  title,
  profile,
  back,
  navigation,
  style,
  renderActions,
  backRoute,
  sub,
  align
}) => {
  const [theme] = useTheme()

  const handleSelectProfile = () => {
    navigation.navigate("Profile", { profileId: profile.id })
  }

  return (
    <Container
      style={{
        width: "100%",
        zIndex: 10,
        justifyContent: !align || align === "center" ? "center" : "flex-start",
        marginBottom: theme.padding,
        ...style
      }}
      safe
      pad
      row
    >
      {back ? (
        <IconButton
          icon="chevron-left"
          background={false}
          iconSize={24}
          onPress={() =>
            backRoute ? navigation.navigate(backRoute) : navigation.goBack()
          }
        />
      ) : null}
      {profile ? (
        <TouchableOpacity onPress={handleSelectProfile}>
          <Avatar
            profile={profile}
            size={55}
            style={{ marginLeft: theme.padding, marginRight: theme.margin }}
          />
        </TouchableOpacity>
      ) : null}
      <Title
        sub={sub}
        style={{ marginLeft: !profile && !back ? theme.padding : 0 }}
      >
        {title ||
          `${profile.name}${profile.surname ? ` ${profile.surname}` : ""}`}
      </Title>
      {renderActions ? (
        <View
          style={{
            alignSelf: "flex-end",
            display: "flex",
            height: "100%",
            alignItems: "center",
            flexDirection: "row",
            marginLeft: "auto",
            marginRight: theme.padding
          }}
        >
          {renderActions()}
        </View>
      ) : null}
    </Container>
  )
}

Header.defaultProps = {
  back: true,
  align: "left",
  sub: false
}

export default withNavigation(Header)
