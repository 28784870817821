import React from "react"
import { Text, Title } from "./Text"
import useMe from "../../hooks/useMe"
import Container from "./Container"

const Name = ({ profile, full, title, ...props }) => {
  const me = useMe()
  const TextComponent = title ? Title : Text
  let displayName = profile.name
  if (profile.surname) {
    if (full) {
      displayName = `${displayName}`
    } else {
      displayName = `${displayName} ${profile.surname[0].toUpperCase()}.`
    }
  }
  if (profile.id === me.id) displayName = `${displayName} (You)`
  if (full) {
    return (
      <Container column style={{ width: "auto" }}>
        <TextComponent {...props}>{displayName}</TextComponent>
        {profile.surname && full ? (
          <TextComponent {...props}>{profile.surname}</TextComponent>
        ) : null}
      </Container>
    )
  } else {
    return <TextComponent {...props}>{displayName}</TextComponent>
  }
}

Name.defaultProps = {
  title: false,
  full: false
}

export default Name
