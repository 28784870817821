import React from "react"
import PeopleSelector from "../components/people/PeopleSelector"
import { useLocalization } from "../localization"
import Modal from "../components/ui/Modal"
import useMe from "../hooks/useMe"
import useMessages from "../hooks/useMessages"

const NewFeedback = ({ navigation }) => {
  const profile = useMe()
  const {
    actions: { getOrSelectThread }
  } = useMessages()
  const { i18n } = useLocalization()

  const handleSelect = person => {
    const thread = getOrSelectThread({ from: profile, to: person })
    navigation.navigate("Thread", {
      threadId: thread.id,
      to: thread.to,
      from: thread.from
    })
  }
  return (
    <Modal full>
      <PeopleSelector
        onSelect={handleSelect}
        prompt={i18n.t("feedback.searchPrompt")}
      />
    </Modal>
  )
}

export default NewFeedback
