import React from "react"
import Container from "../components/ui/Container"
import { Title, TextSecondary } from "../components/ui/Text"
import { themes, useTheme } from "../theme"
import Button from "../components/ui/Button"
import { useLocalization, languagues } from "../localization"
import Modal from "../components/ui/Modal"
import { useNavigation } from "react-navigation-hooks"
import useAppState from "../hooks/useAppState"
import { useQuery } from "@apollo/react-hooks"

import SPACES_QUERY from "../graphql/queries/spaces-query"

const SpaceSwitcher = () => {
  const {
    state: { space },
    actions: { setSpace }
  } = useAppState()
  const { data, loading, error } = useQuery(SPACES_QUERY)
  if (loading) return null

  const switchToSpaces = data.spaceProfiles

  return switchToSpaces.length ? (
    <Container pad>
      <Title>Switch Space</Title>
      {switchToSpaces.map(sp => {
        const selected = sp.space.id === space.id
        return (
          <Button
            key={sp.id}
            title={sp.space.name}
            icon={selected ? "check" : null}
            disabled={selected}
            onPress={() => setSpace(sp.space.id)}
          />
        )
      })}
    </Container>
  ) : null
}

const Settings = () => {
  const {
    actions: { signOut }
  } = useAppState()
  const [theme, setTheme] = useTheme()
  const themeList = Object.keys(themes).map(k => themes[k])
  const { i18n, setLocale, locale } = useLocalization()
  const navigation = useNavigation()

  const handleSignOut = async () => {
    navigation.navigate("SignOut")
  }

  return (
    <Modal>
      <Container scroll>
        {/* <Header title={i18n.t("settings.title")} /> */}
        <Container>
          <Title>{i18n.t("settings.theme")}</Title>
          {themeList.map((themeOption, i) => {
            return (
              <Button
                key={i}
                title={themeOption.label}
                icon={theme.key === themeOption.key ? "check" : null}
                onPress={() => {
                  setTheme(themeOption.key)
                }}
              />
            )
          })}
        </Container>
        {/* <Container pad>
          <Title style={{ fontSize: 18 }}>{i18n.t("settings.language")}</Title>
          {languagues.map((lang, i) => {
            return (
              <Button
                key={i}
                title={lang.label}
                icon={lang.locale === locale ? "check" : null}
                onPress={() => {
                  setLocale(lang.locale)
                }}
              />
            )
          })}
        </Container> */}
        <Container>
          <Button title={"Sign out"} onPress={handleSignOut} />
        </Container>
        <SpaceSwitcher />
        {/* <Container pad>
          <TextSecondary>
            {pluralize("cached messages", messages ? messages.length : 0, true)}
          </TextSecondary>
          <Button onPress={handleClearData} title="Clear Data" />
        </Container> */}
      </Container>
    </Modal>
  )
}

export default Settings
