import React from "react"
import Container from "../ui/Container"
import { TextSecondary } from "../ui/Text"
import { useTheme } from "../../theme"
import { FlatList } from "react-native"
import PointFeedback from "./PointFeedback"
import { useLocalization } from "../../localization"
import usePoints from "../../hooks/usePoints"
import { Avatar, Icon } from "../ui"

const PointListItem = ({ point }) => {
  const [theme] = useTheme()
  const { formatDate } = useLocalization()

  const { selectPoint } = usePoints()

  const pointDefinition = selectPoint(point.key)
  return (
    <Container row transparent style={{ paddingVertical: theme.padding / 2 }}>
      {/* <Avatar size={18} user={item.user} /> */}
      <Container
        row
        transparent
        center
        style={{ width: "auto", marginRight: theme.margin / 2 }}
      >
        <Avatar profile={point.from} size={20} />
        <Icon
          icon="arrow-right"
          size={18}
          style={{ marginHorizontal: theme.margin / 3 }}
        />
        <Avatar profile={point.to} size={20} />
      </Container>
      <PointFeedback
        point={pointDefinition}
        size="small"
        value={point}
        showQualitativeDescriptor={false}
        showActivity={true}
      />
      {/* <TextSecondary>
        {" "}
        from {point.from.name} {point.from.surname[0]}.
      </TextSecondary> */}
      <TextSecondary style={{ marginLeft: "auto" }}>
        {formatDate(point.created, "MM/D")}
      </TextSecondary>
    </Container>
  )
}

const PointList = ({ points, ...props }) => {
  return (
    <FlatList
      data={points}
      keyExtractor={point => point.id}
      style={{ flexGrow: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
      renderItem={({ item }) => {
        return <PointListItem point={item} />
      }}
      {...props}
    />
  )
}

export default PointList
