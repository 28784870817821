import React, { useState, useEffect } from "react"
import { useTheme } from "../../theme"
import pluralize from "pluralize"
import { Container, Title, Emoji, Loading, Text } from "../ui"
import { TouchableOpacity, FlatList } from "react-native"
import hapticImpact from "../../utils/hapticImpact"
import Modal from "../ui/Modal"
import Button from "../ui/Button"
import { useLocalization } from "../../localization"
import usePoints from "../../hooks/usePoints"
import useMe from "../../hooks/useMe"
import usePeople from "../../hooks/usePeople"

const PointSelector = ({ navigation, profile, models, to, onSelect }) => {
  const [theme] = useTheme()

  const { i18n } = useLocalization()

  const [feedbackFor, setFeedbackFor] = useState(to)

  const handleSelect = model => {
    const points = model.points
    const feedbackType =
      feedbackFor.id === profile.id ? "feedback_request" : "feedback"

    onSelect({ points, feedbackFor, type: feedbackType })
    navigation.goBack()
  }
  return (
    <>
      <Title style={{ marginBottom: theme.margin }}>{to.name}'s...</Title>
      {to.id !== profile.id && false ? (
        <Container row>
          <Button
            link={feedbackFor.id !== to.id}
            icon="arrow-right-circle"
            title={`${i18n.t("points.give")} ${to.name}`}
            onPress={() => setFeedbackFor(to)}
          />
          <Button
            link={feedbackFor.id !== profile.id}
            icon="arrow-left-circle"
            title={`${i18n.t("points.request")} ${to.name}`}
            onPress={() => setFeedbackFor(user)}
          />
        </Container>
      ) : null}
      <FlatList
        data={models}
        keyExtractor={(item, i) => `${item.key}-${i}`}
        contentContainerStyle={{
          flex: 1
          // padding: theme.padding
        }}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              onPress={async () => {
                await hapticImpact()
                handleSelect(item)
              }}
            >
              <Container
                row
                pad
                surface
                round
                style={{
                  marginBottom: theme.margin
                }}
              >
                <Emoji emoji={item.emoji} size={24} />
                <Title sub style={{ marginLeft: theme.margin }}>
                  {/* {feedbackFor
                    ? feedbackFor.id !== profile.id
                      ? `${feedbackFor.name}ʼs `
                      : "My "
                    : ""} */}
                  {item.label}
                </Title>
                <Text style={{ marginLeft: "auto" }}>
                  {pluralize("point", item.points.length, true)}
                </Text>
                {/* <Button
                link
                icon="play-circle"
                title="All"
                style={{ marginLeft: "auto" }}
              /> */}
              </Container>
            </TouchableOpacity>
          )
        }}
      />
      {/* {numSelected ? (
        <Button
          primary
          title={`Select ${pluralize("point", numSelected, true)}`}
          onPress={handleSelectPoints}
        />
      ) : null} */}
    </>
  )
}

const PointSelectorContainer = ({ navigation }) => {
  const profile = useMe()
  const { selectProfile, loading } = usePeople()
  const onSelect = navigation.getParam("onSelect")
  const { model } = usePoints()
  const models = model.points
  const toProfile = selectProfile(navigation.getParam("to"))

  // useEffect(() => {

  //   if (!loading) {
  //     console.log("setting toProfile", toProfile)
  //     setTo(toProfile)
  //   }
  // }, [loading])

  return (
    <Modal>
      {loading || !toProfile ? (
        <Loading />
      ) : (
        <PointSelector
          models={models}
          to={toProfile}
          onSelect={onSelect}
          profile={profile}
          navigation={navigation}
        />
      )}
    </Modal>
  )
}

export default PointSelectorContainer
