import React from "react"
import PeopleList from "../components/people/People"
import Header from "../components/ui/Header"
import { Container } from "../components/ui"

const PeopleScreen = () => {
  return (
    <Container full safe>
      <Header
        title={"People"}
        // title={profile.name}
        align="left"
        back={false}
        // renderActions={() => {
        //   return (
        //     <>
        //       <IconButton
        //         icon="send"
        //         primary
        //         onPress={() => navigation.navigate("NewFeedback")}
        //       />
        //     </>
        //   )
        // }}
      />
      <PeopleList />
    </Container>
  )
}

export default PeopleScreen
