import React from "react"
import { TextSecondary } from "../../ui/Text"
import Bubble from "../../ui/Bubble"
import Button from "../../ui/Button"
import { useTheme } from "../../../theme"
import { Container, Icon } from "../../ui"
import useMe from "../../../hooks/useMe"

const FeedbackRequest = ({ message, onSelect }) => {
  const me = useMe()
  const [theme] = useTheme()
  const withUser = message.from
  const isRequestForMe = me.id === message.to.id

  const handleSelect = () => {
    onSelect(message)
  }

  const renderSubject = () => {
    return isRequestForMe ? (
      <TextSecondary
        style={{
          fontStyle: "italic"
        }}
      >
        {withUser.name} requested feedback
      </TextSecondary>
    ) : (
      <TextSecondary
        style={{
          fontStyle: "italic"
        }}
      >
        You requested freedback
      </TextSecondary>
    )
  }

  const renderProgress = () => {
    const total = message.data.points.length
    const done = message.pointProgress
      ? Object.keys(message.pointProgress).length
      : 0
    return (
      <Container transparent row>
        {message.progress === 1 ? (
          <Icon icon="check" />
        ) : (
          <Icon icon="loader" color={theme.palette.textSecondary} />
        )}
        <TextSecondary style={{ marginLeft: theme.margin / 2 }}>
          {done} / {total} given
        </TextSecondary>
      </Container>
    )
  }

  const renderAction = () => {
    if (!isRequestForMe) return null
    if (message.progress === 1) return null
    const label =
      message.progress > 0 && message.progress < 1
        ? "Continue"
        : "Give Feedback"
    return <Button secondary title={label} onPress={handleSelect} />
  }

  return (
    <Bubble text={message.comment} style={{ padding: theme.padding }}>
      {renderSubject()}
      {renderProgress()}
      {renderAction()}
    </Bubble>
  )
}

export default FeedbackRequest
