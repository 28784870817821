import React, { useEffect, useState } from "react"
import {
  Container,
  Title,
  Paragraph,
  Button,
  ErrorMessage
} from "../components/ui"
import { useNavigation } from "react-navigation-hooks"
import PinInput from "../components/ui/PinInput"
import { useAuthPin } from "../hooks/useAuthLink"
import useAppState from "../hooks/useAppState"
import { KeyboardAvoidingView } from "react-native"

const Authenticate = () => {
  const navigation = useNavigation()
  const [pin, setPin] = useState("")
  const {
    actions: { setSpace },
    state: { profile }
  } = useAppState()

  const [authWithPin, { loading, finished, data, error }] = useAuthPin(pin)

  const handleAuthWithPin = () => {
    if (pin && pin.length === 6) authWithPin({ variables: { pin } })
  }

  useEffect(() => {
    if (finished) {
      if (error) {
        setPin("")
      } else if (data) {
        setSpace(data.authWithPin.space.id)
      }
    }
  }, [finished, error])

  useEffect(() => {
    if (profile !== null && profile.id) {
      navigation.navigate("Inbox")
    }
  }, [profile])

  const handleRequestLogin = () => {
    navigation.navigate("RequestAuthLink")
  }

  return (
    <KeyboardAvoidingView behavior="padding" enabled style={{ flex: 1 }}>
      <Container full safe>
        <Container full pad center>
          <Title>Sign in</Title>
          <Paragraph>
            Enter your login code or tap the link sent to your email address
          </Paragraph>
          {error ? <ErrorMessage error={error} /> : null}
          <PinInput onChangeText={setPin} value={pin} />
          <Button
            primary
            full
            disabled={pin.length !== 6}
            title={loading || finished ? "Signing in..." : "Sign in"}
            onPress={handleAuthWithPin}
          />
          <Button link title="Request new login" onPress={handleRequestLogin} />
        </Container>
      </Container>
    </KeyboardAvoidingView>
  )
}

export default Authenticate
