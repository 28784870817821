import React, { useState, useEffect } from "react";
import { FlatList, RefreshControl } from "react-native";
import { ErrorMessage, Container, Loading } from "../ui";
import { useTheme } from "../../theme";
import useMessages from "../../hooks/useMessages";
import ThreadItem from "./ThreadItem";
import useMe from "../../hooks/useMe";
import useDimensions from "../../hooks/useDimensions";

const Threads = ({ onSelectThread }) => {
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const profile = useMe();
  const { isMobile } = useDimensions();
  const {
    state: { sortedThreads, loading, error },
    actions: { reload }
  } = useMessages();

  const [refreshing, setRefreshing] = useState(false);
  const handleRefresh = async () => {
    setRefreshing(true);
    await reload();
    setRefreshing(false);
  };

  const handleSelectThread = thread => {
    setSelectedThreadId(thread.id);
    onSelectThread(thread);
  };

  useEffect(() => {
    if (!isMobile && sortedThreads.length) {
      handleSelectThread(sortedThreads[0]);
    }
  }, [isMobile, sortedThreads.length]);

  const [theme] = useTheme();

  if (loading)
    return (
      <Container full center>
        <Loading />
      </Container>
    );
  if (error)
    return (
      <Container full center>
        <ErrorMessage error={error} />
      </Container>
    );

  return (
    <FlatList
      contentContainerStyle={{
        flexGrow: 1
        // padding: theme.padding
      }}
      refreshControl={
        <RefreshControl
          tintColor={theme.palette.textSecondary}
          colors={[theme.palette.textSecondary]}
          refreshing={refreshing}
          onRefresh={handleRefresh}
        />
      }
      data={sortedThreads}
      keyExtractor={thread => thread.id}
      renderItem={({ item, index }) => {
        const selected = item.id == selectedThreadId;
        return (
          <ThreadItem
            me={profile}
            key={`item-${index}`}
            onSelect={handleSelectThread}
            thread={item}
            selected={selected}
          />
        );
      }}
    />
  );
};

export default Threads;
