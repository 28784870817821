import React, { useState } from "react"
import { useTheme } from "../../theme"
import { Loading, ErrorMessage } from "../ui"
import { FlatList, RefreshControl } from "react-native"
import usePeople from "../../hooks/usePeople"
import useMe from "../../hooks/useMe"
import PeopleItem from "./PeopleItem"
import { useNavigation } from "react-navigation-hooks"

const People = ({ profiles, refetch }) => {
  const [theme] = useTheme()
  const navigation = useNavigation()
  const [refreshing, setRefreshing] = useState(false)
  const handleRefresh = async () => {
    setRefreshing(true)
    refetch()
    // setRefreshing(false)
  }
  const handleSelect = profile => () => {
    navigation.navigate("Profile", { profileId: profile.id })
  }
  return (
    <FlatList
      numColumns={2}
      contentContainerStyle={{}}
      refreshControl={
        <RefreshControl
          tintColor={theme.palette.textSecondary}
          colors={[theme.palette.textSecondary]}
          refreshing={refreshing}
          onRefresh={handleRefresh}
        />
      }
      data={profiles}
      keyExtractor={profile => profile.id}
      renderItem={({ item, index }) => {
        return <PeopleItem profile={item} onSelect={handleSelect(item)} />
      }}
    />
  )
}

const PeopleContainer = props => {
  const profile = useMe()
  const { people, loading, error, refetch } = usePeople()
  // TODO: sort these by last updated (messaged) by user (or by anyone)?
  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  return (
    <People profile={profile} refetch={refetch} profiles={people} {...props} />
  )
}

export default PeopleContainer
