import React, { useState, useEffect, useRef } from "react"
import { Animated } from "react-native"
import Color from "color"
import { useTheme } from "../../theme"
import useAnimation from "../../hooks/useAnimation"

const TouchPoint = ({ position, animate = false, size = 0 }) => {
  const [theme] = useTheme()
  // const [animated, setAnimated] = useState(!animate ? true : false)
  // const scale = useRef(new Animated.Value(0))

  const { animating, lerp } = useAnimation({
    duration: 300,
    useNativeDriver: true
  })
  const animated = !animating

  const computeStyle = () => {
    let csize = animated ? size : 60
    const baseStyle = {
      top: position.y - csize / 2,
      left: position.x - csize / 2,
      position: "absolute",
      width: csize,
      height: csize,
      borderRadius: "50%"
    }

    const color = new Color(theme.palette.accent).lighten(0.3).toString()

    return animated
      ? {
          ...baseStyle,
          shadowColor: color,
          shadowOpacity: 0.9,
          shadowRadius: 1.5,
          shadowOffset: { width: 0, height: 0 },
          backgroundColor: new Color(theme.palette.accent).alpha(0.5).toString()
        }
      : {
          ...baseStyle,
          borderWidth: theme.borderWidth,
          borderColor: color,
          transform: [{ scale: lerp([0, 1]) }]
        }
  }

  return <Animated.View pointerEvents="none" style={computeStyle()} />
}
TouchPoint.defaultProps = {
  size: 7
}

export default TouchPoint
