import React from "react"
import pluralize from "pluralize"
import InputSubmit from "./InputSubmit"
import { Container, Title, Text, TextSecondary } from "../../ui"
import { useTheme } from "../../../theme"
import Button from "../../ui/Button"

const RequestFeedbackInput = ({ points, from, onSubmit, onChangePoints }) => {
  const [theme] = useTheme()

  const handleChangePoints = () => {
    onChangePoints(from)
  }

  const handleSubmit = comment => {
    onSubmit({ comment, points: points.map(pt => pt.id) })
  }

  const renderPoints = () => {
    const threshold = 5
    const pts = [...points].slice(0, threshold)
    return (
      <Container transparent>
        <Container scroll horizontal transparent row>
          {/* {pts.map((pt, i) => (
            <PointBadge
              key={i}
              point={pt}
              style={{ marginRight: theme.margin / 2 }}
            />
          ))} */}
        </Container>
        <Container row center transparent>
          <TextSecondary>
            {pluralize("point", points.length, true)} selected
          </TextSecondary>
          <Button title="Change" onPress={handleChangePoints} />
        </Container>
      </Container>
    )
  }

  return (
    <Container column pad margin row surface round>
      <Title sub>Feedback Request</Title>
      {renderPoints()}
      <InputSubmit onSubmit={handleSubmit} />
    </Container>
  )
}

export default RequestFeedbackInput
