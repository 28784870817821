import React from "react"
import MultiPointInput from "../input/MultiPointInput"
import { Title, Container, Paragraph } from "../ui"
import { useTheme } from "../../theme"
import TextInput from "../input/Text"
import Note from "../input/Note"

const inputs = {
  MultiPointInput: props => <MultiPointInput {...props} />,
  Text: props => <TextInput mulitline {...props} />,
  Note: props => <Note mode="edit" {...props} />
}

const PointInput = ({ point, onChange }) => {
  const InputComponent = inputs[point.type]
  const [theme] = useTheme()

  if (!InputComponent) return null

  const handleChange = value => {
    onChange(value)
  }

  const props = {
    point,
    value: point.submission,
    onChange: handleChange
  }

  return (
    <Container transparent center>
      <InputComponent {...props} />
    </Container>
  )
}

export default PointInput
