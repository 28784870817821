import React from "react";
import { useTheme } from "../../../theme";
import Bubble from "../../ui/Bubble";
import PointFeedback from "../../point/PointFeedback";

import { Container } from "../../ui";
import Avatar from "../../ui/Avatar";
import useMe from "../../../hooks/useMe";
import usePoints from "../../../hooks/usePoints";

const PointMessage = ({ point, from, body, showAvatar, justifyContent }) => {
  const profile = useMe();
  const [theme] = useTheme();
  const { selectPoint } = usePoints();
  const pointDef = selectPoint(point.key);
  return (
    <>
      {showAvatar === true ? (
        <Container
          transparent
          row
          style={{
            justifyContent,
            width: "100%",
            borderWidth: 1
          }}
        >
          <Avatar profile={message.from} />
        </Container>
      ) : null}
      <PointFeedback
        point={pointDef}
        value={point}
        showQualitativeDescriptor={false}
      />
      {body ? (
        <Container transparent row style={{ justifyContent }}>
          {from.id !== profile.id ? (
            <Avatar
              profile={from}
              size={24}
              style={{ marginRight: theme.margin }}
            />
          ) : null}
          <Bubble text={body} highlight={from.id === profile.id} />
        </Container>
      ) : null}
      {/* <TextSecondary>{moment(date).calendar()}</TextSecondary> */}
    </>
  );
};

PointMessage.defaultProps = {
  align: "left"
};

export default PointMessage;
