import React, { useState } from "react"
import { useTheme } from "../../theme"
import { Title, Container } from "../ui"
import Avatar from "../ui/Avatar"
import ProfileMenu from "./ProfileMenu"
import ProfilePoints from "./ProfilePoints"
import ProfileActivity from "./ProfileActivity"

const Profile = ({ profile, navigation }) => {
  const [theme] = useTheme()
  const options = [
    { title: "Points", icon: "circle" },
    { title: "Activity", icon: "activity" }
  ]

  const [selectedMenuItem, setSelectedMenuItem] = useState(options[0])

  const handleSelectMenu = menuItem => {
    setSelectedMenuItem(menuItem)
  }

  return (
    <>
      <Container style={{ alignItems: "center" }} pad surface row>
        <Avatar
          size={80}
          profile={profile}
          style={{ marginRight: theme.margin }}
        />
        <Container column transparent style={{ width: "auto" }}>
          <Title>{profile.name}</Title>
          {profile.surname ? <Title>{profile.surname}</Title> : null}
        </Container>
      </Container>
      <ProfileMenu
        selectedOption={selectedMenuItem}
        options={options}
        onSelect={handleSelectMenu}
      />
      {selectedMenuItem.title === "Points" ? (
        <ProfilePoints profile={profile} />
      ) : (
        <ProfileActivity profile={profile} />
      )}
    </>
  )
}

export default Profile
