import React from "react"

import { useQuery } from "@apollo/react-hooks"
import Container from "../ui/Container"
import { Loading, ErrorMessage } from "../ui"
import PointList from "../point/PointList"
import POINT_ACTIVITY_QUERY from "../../graphql/queries/activity-query"
import PointStats from "./PointStats"
import { useLocalization } from "../../localization"

const ProfileActivity = ({ profile }) => {
  const { data, error, loading } = useQuery(POINT_ACTIVITY_QUERY, {
    variables: { id: profile.id },
    fetchPolicy: "cache-and-network"
  })
  return (
    <Container full pad>
      {loading ? <Loading /> : null}
      {error ? <ErrorMessage error={error} /> : null}
      {data ? (
        <Container full>
          <PointStats profile={profile} />
          <PointList points={data.profile.allPoints} />
        </Container>
      ) : null}
    </Container>
  )
}

export default ProfileActivity
