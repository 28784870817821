import React from "react"
import { View, Animated } from "react-native"
import { useTheme } from "../../theme"

const Card = ({ children, style, ...props }) => {
  const [theme] = useTheme()
  return (
    <Animated.View
      style={{
        backgroundColor: theme.palette.surface,
        borderRadius: theme.borderRadius,
        borderWidth: 0,
        borderColor: theme.palette.border,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 20,
        shadowOpacity: 0.5,
        ...style
      }}
      {...props}
    >
      {children}
    </Animated.View>
  )
}

export default Card
