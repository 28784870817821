import gql from "graphql-tag"
import userFragment from "../fragments/user-fragment"
import profileFragment from "../fragments/profile-fragment"
import spaceFragment from "../fragments/space-fragment"

export default gql`
  mutation AuthWithLink($linkId: ID!) {
    authWithMagicLink(linkId: $linkId) {
      token
      user {
        ...UserFragment
      }
      space {
        ...SpaceFragment
      }
      profile {
        ...ProfileFragment
      }
    }
  }
  ${userFragment}
  ${profileFragment}
  ${spaceFragment}
`
