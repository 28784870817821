import React, { useEffect } from "react"
import useAppState from "../hooks/useAppState"
import { Container, Loading } from "../components/ui"

const SignOut = ({ navigation }) => {
  const {
    actions: { signOut }
  } = useAppState()

  useEffect(() => {
    const doSignOut = async () => {
      await signOut()
      navigation.navigate("Authenticate")
    }
    doSignOut()
  }, [])

  return (
    <Container full center>
      <Loading />
    </Container>
  )
}

export default SignOut
