import React from "react"
import { TouchableOpacity, View } from "react-native"
import { Text, Label } from "./Text"
import { useTheme } from "../../theme"

import hapticImpact from "../../utils/hapticImpact"
import Emoji from "./Emoji"
import Icon from "./Icon"
import { withNavigation } from "react-navigation"

const Button = ({
  title,
  children,
  onPress,
  highlighted,
  disabled,
  link,
  icon,
  iconProps,
  style,
  labelStyle,
  route,
  modal,
  navigation,
  emoji,
  primary,
  secondary,
  full
}) => {
  if (children && title)
    console.warn(
      "the title prop of button will be ignored if children are passed."
    )

  const handlePress = async e => {
    await hapticImpact()
    if (onPress) onPress(e)
    if (route) {
      const ops = {}
      if (modal) ops.mode = "modal"
      navigation.navigate(route, null, ops)
    }
  }
  const [theme] = useTheme()

  let backgroundColor = theme.palette.surface
  let color = theme.palette.text
  let padding = theme.padding
  if (primary) {
    backgroundColor = theme.palette.accent
    color = theme.palette.inverseText
  } else if (link) {
    backgroundColor = "transparent"
    padding = theme.padding / 2
  } else if (secondary) {
    backgroundColor = theme.palette.accent
    color = theme.palette.inverseText
    padding = theme.padding / 2
  }

  if (full) style = { width: "100%", ...style }

  if (highlighted === true && link === true) {
    // TODO: handle non-link case?
    labelStyle = { ...labelStyle, color: theme.palette.accent }
    iconProps = { ...iconProps, color: theme.palette.accent }
  }

  return (
    <TouchableOpacity
      style={{
        backgroundColor,
        marginVertical: theme.margin,
        padding,
        borderRadius: theme.borderRadius,
        borderColor: theme.palette.surface,
        opacity: disabled ? 0.5 : 1,
        maxWidth: "auto",
        ...style
      }}
      onPress={handlePress}
      disabled={disabled}
    >
      {children || (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {emoji ? (
            <Emoji emoji={emoji} style={{ marginRight: theme.margin / 2 }} />
          ) : null}
          {icon ? (
            <Icon
              icon={icon}
              style={{ marginRight: theme.margin / 2 }}
              color={color}
              {...iconProps}
            />
          ) : null}
          <Label style={labelStyle}>{title}</Label>
        </View>
      )}
    </TouchableOpacity>
  )
}

Button.defaultProps = {
  link: false,
  route: null,
  highlighted: false,
  labelStyle: {},
  iconProps: {}
}

export default withNavigation(Button)
