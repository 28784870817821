import React from "react"

import {
  View,
  TouchableHighlight,
  Animated,
  StyleSheet,
  Platform
} from "react-native"
import PropTypes from "prop-types"
import { Text } from "./Text"
import { TextInput } from "."
import { withTheme } from "../../theme"

// Note: copied from https://github.com/theshadow2393/react-native-pin-input-component

class Cell extends React.Component {
  constructor() {
    super()
    this.state = { fade: new Animated.Value(0) }
  }

  componentDidUpdate() {
    const { fade } = this.state
    Animated.loop(Animated.timing(fade, { toValue: 1, duration: 1000 })).start()
  }

  render() {
    const {
      value,
      isFocus,
      entered,
      cellNormalStyle,
      cellFocusStyle,
      cellBlurStyle,
      selectionColor,
      visibleSelection,
      theme
    } = this.props
    const { fade } = this.state
    const focusStyle = {
      ...cellFocusStyle,
      borderColor: theme.palette.accent,
      borderBottomWidth: theme.borderWidth
    }
    if (isFocus) {
      if (visibleSelection) {
        return (
          <View style={focusStyle}>
            <Animated.View style={{ opacity: fade }}>
              <View
                style={{
                  width: 2,
                  height: 20,
                  backgroundColor: theme.palette.accent
                }}
              />
            </Animated.View>
          </View>
        )
      }
      return <View style={focusStyle} />
    }
    return (
      <View style={entered ? cellBlurStyle : cellNormalStyle}>
        <Text>{value}</Text>
      </View>
    )
  }
}

Cell = withTheme(Cell)

Cell.propTypes = {
  value: PropTypes.string.isRequired,
  isFocus: PropTypes.bool,
  entered: PropTypes.bool,
  cellNormalStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  cellFocusStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  cellBlurStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  selectionColor: PropTypes.string,
  visibleSelection: PropTypes.bool
}

Cell.defaultProps = {
  isFocus: false,
  entered: false,
  selectionColor: "red",
  visibleSelection: false
}

const defaultStypes = StyleSheet.create({
  normal: {
    width: 40,
    height: 40,
    borderBottomWidth: 0.5,
    borderColor: "#D5D5D5",
    justifyContent: "center",
    alignItems: "center",
    margin: 2
  },
  focus: {
    width: 40,
    height: 40,
    borderBottomWidth: 0.5,
    borderColor: "#D5D5D5",
    justifyContent: "center",
    alignItems: "center",
    margin: 2
  },
  blur: {
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    margin: 2
  }
})

class PinInput extends React.Component {
  constructor() {
    super()
    this.state = {
      onFocus: false
    }
  }

  renderChars() {
    const { onFocus } = this.state
    const {
      value,
      visibleSelection,
      selectionColor,
      length,
      cellNormalStyle,
      cellFocusStyle,
      cellBlurStyle,
      CellView,
      FocusView,
      BlurView,
      theme
    } = this.props
    const cells = []

    const values = value.split("")
    const l = values.length
    const normalStyle = {
      ...cellNormalStyle,
      borderColor: theme.palette.text,
      borderWidth: theme.borderWidth
    }
    if (CellView === undefined) {
      for (let i = 0; i < length; i += 1) {
        if (i < l)
          cells.push(
            <Cell
              key={`cell-${i}`}
              value={values[i]}
              entered
              cellNormalStyle={normalStyle}
              cellFocusStyle={cellFocusStyle}
              cellBlurStyle={cellBlurStyle}
              visibleSelection={visibleSelection}
              selectionColor={selectionColor}
            />
          )
        else if (i === l) {
          if (onFocus) {
            cells.push(
              <Cell
                key={`cell-last-${i}`}
                cellNormalStyle={normalStyle}
                cellFocusStyle={cellFocusStyle}
                cellBlurStyle={cellBlurStyle}
                value=""
                isFocus
                visibleSelection={visibleSelection}
                selectionColor={selectionColor}
              />
            )
          } else {
            cells.push(
              <Cell
                key={`cell-other-${i}`}
                cellNormalStyle={normalStyle}
                cellFocusStyle={cellFocusStyle}
                cellBlurStyle={cellBlurStyle}
                value=""
                visibleSelection={visibleSelection}
                selectionColor={selectionColor}
              />
            )
          }
        } else
          cells.push(
            <Cell
              key={`cell-other2-${i}`}
              cellNormalStyle={normalStyle}
              cellFocusStyle={cellFocusStyle}
              cellBlurStyle={cellBlurStyle}
              value=""
              visibleSelection={visibleSelection}
              selectionColor={selectionColor}
            />
          )
      }
    } else {
      for (let i = 0; i < length; i += 1) {
        if (i < l) cells.push(BlurView)
        else if (i === l) {
          if (onFocus) {
            cells.push(FocusView)
          } else {
            cells.push(CellView)
          }
        } else cells.push(CellView)
      }
    }

    return cells
  }

  render() {
    const { value, onChangeText, autoFocus, onPress } = this.props
    let otp
    return (
      <TouchableHighlight
        underlayColor="transparent"
        onPress={() => {
          otp.focus()
          if (onPress) onPress()
        }}
      >
        <View>
          <TextInput
            display="none"
            ref={component => {
              otp = component
            }}
            autoFocus={autoFocus}
            value={value}
            onChangeText={onChangeText}
            maxLength={6}
            keyboardType="numeric"
            onBlur={() => {
              this.setState({ onFocus: false })
            }}
            onFocus={() => {
              this.setState({ onFocus: true })
            }}
          />
          <View style={{ flexDirection: "row" }}>{this.renderChars()}</View>
        </View>
      </TouchableHighlight>
    )
  }
}
PinInput = withTheme(PinInput)

PinInput.propTypes = {
  value: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  onChangeText: PropTypes.func.isRequired,
  onPress: PropTypes.func,
  length: PropTypes.number,
  selectionColor: PropTypes.string,
  visibleSelection: PropTypes.bool,
  cellNormalStyle: PropTypes.objectOf(PropTypes.any),
  cellFocusStyle: PropTypes.objectOf(PropTypes.any),
  cellBlurStyle: PropTypes.objectOf(PropTypes.any),
  CellView: PropTypes.objectOf(PropTypes.any),
  FocusView: PropTypes.objectOf(PropTypes.any),
  BlurView: PropTypes.objectOf(PropTypes.any)
}

PinInput.defaultProps = {
  autoFocus: false,
  length: 6,
  selectionColor: "red",
  visibleSelection: false,
  cellNormalStyle: defaultStypes.normal,
  cellFocusStyle: defaultStypes.focus,
  cellBlurStyle: defaultStypes.blur,
  CellView: undefined,
  FocusView: undefined,
  BlurView: undefined
}
export default PinInput
