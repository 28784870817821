import React from "react"
import pluralize from "pluralize"
import { useQuery } from "@apollo/react-hooks"
import Container from "../ui/Container"
import { useTheme } from "../../theme"
import { Loading, ErrorMessage, TextSecondary } from "../ui"

import POINT_STATS_QUERY from "../../graphql/queries/point-stats-query"
import { useLocalization } from "../../localization"

const PointStats = ({ profile }) => {
  const [theme] = useTheme()
  const { formatDate } = useLocalization()
  const { data, error, loading } = useQuery(POINT_STATS_QUERY, {
    variables: { id: profile.id },
    fetchPolicy: "cache-and-network"
  })
  const stats = data ? data.profile.stats : null
  const statStyle = { marginRight: 0 }
  return (
    <Container
      row
      center
      pad
      surface
      round
      style={{ marginBottom: theme.margin }}
    >
      {loading ? <Loading /> : null}
      {error ? <ErrorMessage error={error} /> : null}
      {stats ? (
        <>
          {stats.total > 0 ? (
            <>
              <TextSecondary style={statStyle}>
                {pluralize("point", stats.receivedCount, true)} received{" • "}
              </TextSecondary>
              <TextSecondary style={statStyle}>
                {pluralize("point", stats.givenCount, true)} given{" • "}
              </TextSecondary>
              <TextSecondary style={statStyle}>{`${formatDate(
                stats.firstUpdated,
                "M/D"
              )} - ${formatDate(stats.lastUpdated, "M/D")}`}</TextSecondary>
            </>
          ) : (
            <TextSecondary>No activity recorded yet.</TextSecondary>
          )}
          {/* <Text>Stats: {JSON.stringify(stats, null, 2)}</Text> */}
        </>
      ) : null}
    </Container>
  )
}

export default PointStats
