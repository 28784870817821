import React from "react"
import { TextSecondary } from "../../ui"

const Section = ({ message }) => {
  const style =
    message.align && message.align === "center"
      ? { textAlign: "center", width: "100%" }
      : {}
  return <TextSecondary style={{ ...style }}>{message.text}</TextSecondary>
}

export default Section
