import React from "react"
import Container from "../ui/Container"
import { useTheme } from "../../theme"
import { Title, TextSecondary, IconButton } from "../ui"
import { View, TouchableOpacity } from "react-native"
import PointFeedback from "../point/PointFeedback"
import { useNavigation } from "react-navigation-hooks"
import { getThreadId } from "../thread/state"
import useMe from "../../hooks/useMe"

const ProfilePointsGroup = ({ model, onSelectPoint, profile }) => {
  const me = useMe()
  const navigation = useNavigation()
  const [theme] = useTheme()

  const statsByKey = profile.snapshot
  if (!statsByKey) return null

  const pts = model.points.filter(pt => {
    return !!statsByKey[pt.id]
  })

  const handleAddPoints = () => {
    const threadId = getThreadId({ to: profile, from: me })
    navigation.navigate("Thread", {
      threadId,
      to: profile,
      from: me,
      points: model.points.map(pt => pt.id)
    })
  }

  return (
    <Container
      transparent
      round
      pad
      style={{ marginTop: theme.margin, position: "relative" }}
    >
      <Container
        transparent
        row
        style={{
          borderBottomWidth: theme.borderWidth,
          borderBottomColor: theme.palette.border
        }}
      >
        <Title sub>
          {model.emoji} {model.label}
        </Title>
        <IconButton
          size={20}
          iconSize={14}
          containerStyle={{ marginLeft: "auto" }}
          icon="plus"
          onPress={handleAddPoints}
        />
      </Container>

      <Container
        transparent
        row
        style={{ marginTop: theme.margin, flexWrap: "wrap" }}
      >
        {pts.length === 0 ? (
          <TextSecondary>No points recorded</TextSecondary>
        ) : null}
        {pts.map(point => {
          const pointValue = statsByKey[point.id] || { avg: 0 }
          return (
            <TouchableOpacity
              key={point.key}
              onPress={() => onSelectPoint(point)}
            >
              <View
                style={{
                  marginRight: theme.margin,
                  marginBottom: theme.margin
                }}
              >
                <PointFeedback
                  value={{
                    quantitativeValue: pointValue.avg,
                    qualitativeValue: pointValue.qualitativeValue,
                    toId: profile.id,
                    ...pointValue
                  }}
                  point={point}
                  count={pointValue.count}
                  showActivity
                />
              </View>
            </TouchableOpacity>
          )
        })}
      </Container>
    </Container>
  )
}

export default ProfilePointsGroup
