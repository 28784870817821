import React from "react"
import { Container, Text, CircleProgress, Title } from "../ui"
import { useTheme } from "../../theme"
import { Label } from "../ui/Text"

const PointScale = ({ point }) => {
  const { scale } = point
  const [theme] = useTheme()
  return (
    <>
      <Container pad row center transparent style={{ flexWrap: "wrap" }}>
        <Label sub style={{ marginRight: theme.margin }}>
          Scale:{" "}
        </Label>
        {scale.map((s, i) => (
          <Container
            key={i}
            row
            transparent
            style={{
              width: "auto",
              marginRight: theme.margin
            }}
          >
            <CircleProgress
              value={s.value}
              size={16}
              style={{ marginRight: theme.margin / 2 }}
            />
            <Text>{s.label}</Text>
            {/* {profile && qualitativeValue === s.label ? (
            <Avatar
              size={24}
              profile={profile}
              style={{ marginLeft: theme.margin }}
            />
          ) : null} */}
          </Container>
        ))}
      </Container>
    </>
  )
}

export default PointScale
