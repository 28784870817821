import React from "react"
import { Text as RNText } from "react-native"
import { sanFranciscoWeights } from "react-native-typography"
import { useTheme } from "../../theme"
import Container from "./Container"

export const ErrorText = ({ children, style, ...props }) => {
  const [theme] = useTheme()
  return (
    <RNText
      style={{
        ...sanFranciscoWeights.regular,
        fontSize: theme.fontSize.text,
        color: theme.palette.red,
        ...style
      }}
      {...props}
    >
      {children}
    </RNText>
  )
}

export const Text = ({ children, style, ...props }) => {
  const [theme] = useTheme()
  return (
    <RNText
      style={{
        ...sanFranciscoWeights.regular,
        fontSize: theme.fontSize.text,
        color: theme.palette.text,
        ...style
      }}
      {...props}
    >
      {children}
    </RNText>
  )
}

export const TextSecondary = ({ children, style, ...props }) => {
  const [theme] = useTheme()
  return (
    <RNText
      style={{
        ...sanFranciscoWeights.regular,
        fontSize: theme.fontSize.textSecondary,
        color: theme.palette.textSecondary,
        ...style
      }}
      {...props}
    >
      {children}
    </RNText>
  )
}

export const Label = ({ children, style, ...props }) => {
  const [theme] = useTheme()
  return (
    <Text
      style={{
        fontSize: theme.fontSize.text,
        fontWeight: "bold",
        textAlign: "center",
        ...style
      }}
      {...props}
    >
      {children}
    </Text>
  )
}

export const TitleStack = ({ title, ...props }) => {
  const titles = title.split(" ")
  return (
    <Container column transparent>
      {titles.map((t, i) => (
        <Title numberOfLines={1} key={i} {...props}>
          {t}
        </Title>
      ))}
    </Container>
  )
}

export const Title = ({ children, sub, style, ...props }) => {
  const [theme] = useTheme()
  // const baseStyle = useStyle(props)
  const styles = {
    ...sanFranciscoWeights.bold,
    fontSize: !sub ? theme.fontSize.heading1 : theme.fontSize.heading2,
    color: theme.palette.text,
    ...style
  }
  if (theme.fontFamily) styles.fontFamily = theme.fontFamily
  return (
    <RNText style={styles} {...props}>
      {children}
    </RNText>
  )
}

export const Paragraph = ({ style, ...props }) => {
  const [theme] = useTheme()
  return (
    <Text
      style={{
        marginVertical: theme.margin,
        color: theme.palette.textSecondary,
        ...style
      }}
      {...props}
    />
  )
}
