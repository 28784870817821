import React from "react"
import { ActivityIndicator } from "react-native"
import { useTheme } from "../../theme"

const Loading = props => {
  const [theme] = useTheme()
  return <ActivityIndicator color={theme.palette.accent} {...props} />
}

export default Loading
