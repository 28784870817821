import gql from "graphql-tag"

export default gql`
  query ProfileStats($id: ID!) {
    profile(id: $id) {
      id
      stats {
        receivedCount
        givenCount
        total
        lastUpdated
        firstUpdated
      }
    }
  }
`
