import { useLocalization } from "../localization"
import usePoints from "./usePoints"
import { useMemo } from "react"
import useMe from "./useMe"

const useThreadedMessagees = thread => {
  /*
  Groups messages by "sessions" based on time or a particular "subject".
  - Time-based sessions are ad-hoc feedback grouped into X min intervals
  - Subject-based sessions are tied to a particular object or interval (check-in, project grade, etc)
  */

  // Handle a new thread
  const { selectPoint } = usePoints()
  const profile = useMe()
  const { formatDate } = useLocalization()

  const reduceMessages = messages => {
    let sectionMessage = null
    let user = null

    return messages.reduce((messages, message, i) => {
      if (message.type !== "feedback" || !message.points.length) {
        messages.push(message)
        return messages
      }

      const pt = message.points[0]
      const pointModel = selectPoint(pt.key).parent

      // if (!user || message.from.id !== user.id || i >= messages.length - 1) {
      //   user = message.from
      //   if (user.id !== profile.id && i > 0) {
      //     console.log("show avatar...")
      //     messages[i - 1] = { ...messages[i - 1], showAvatar: true }
      //   }
      // }

      if (
        !sectionMessage ||
        (sectionMessage && sectionMessage.to.id !== message.to.id)
      ) {
        const date = message.created || message.clientTime
        messages.push({
          ...message,
          type: "section",
          align: "center",
          text: `${formatDate(date)}`
        })
      }

      if (
        !sectionMessage ||
        (pointModel.key !== sectionMessage.model.key ||
          message.to.id !== sectionMessage.to.id)
      ) {
        sectionMessage = {
          ...message,
          type: "section",
          model: pointModel,
          text: `${
            message.to.id === profile.id ? "Your" : `${message.to.name}'s`
          } ${pointModel.label}`
        }
        messages.push(sectionMessage)
      }
      messages.push(message)
      return messages
    }, [])
  }

  return useMemo(() => reduceMessages(thread.messages), [
    thread.id,
    thread.lastUpdated
  ])
}

export default useThreadedMessagees
